import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, useMediaQuery } from '@material-ui/core'
import palette from 'app_palette'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {
  DollarIcon,
  TimeIcon,
  RefreshIcon,
  ChartBarIcon,
  CashMoneyIcon,
  FullScreenExpandResizeIcon,
  CarFrontIcon,
  DevicePhoneIcon,
  DeviceDesktopIcon,
} from '@77sol/icons/dist'
import { isMobile } from 'react-device-detect'
import { withSize } from 'react-sizeme'
import {
  InfoPaper,
  ReturnPaper,
  BudgetPaper,
  PaymentFormsPaper,
  TextPaper,
  ProposalInfoPaper,
  FinancingPaper,
  PotencyConsumptionPaper,
  ImagesPaper,
  CashFlowModal,
  SchedulePaper,
  ClientPaper,
  InvestingPaperMobile,
  UserOptionsPaper,
  ReturnGraphPaper,
  ImagePaper,

  Button,
  Spacer,
  ProposalTypography as Typography,
  Paper,
} from '@77sol/core'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import API from '../../../../../../api'

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: isMobile ? '24px' : '50px',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#F7F7FC',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  backdrop: {
    zIndex: 30,
    color: '#fff',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
  indicator: {
    backgroundColor: '#1335c6',
  },
  scrollButtons: {
    color: '#707072',
  },
  input: {
    padding: '10px',
  },
  relative: {
    position: 'relative',
  },
  paddingSectionMarginLeft: {
    paddingLeft: '4px',
    position: 'relative',
  },
  paddingSectionMarginRight: {
    paddingRight: '4px',
    position: 'relative',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const initial = [
  {
    id: 'investingContainer',
    content: 'item-investingContainer',
    display: 'Retorno de investimento',
    visible: true,
  },
  {
    id: 'equipmentsContainer',
    content: 'item-equipmentsContainer',
    display: 'Dados de projeto',
    visible: true,
  },
  {
    id: 'paymentsOptionsContainer',
    content: 'item-paymentsOptionsContainer',
    display: 'Financiamento e pagamento',
    visible: true,
  },
  {
    id: 'InfosContainer',
    content: 'item-InfosContainer',
    display: 'Informações extras',
    visible: true,
  },
]

const initialRetornoSubCards = [
  {
    id: 'retornoEmContainer',
    content: 'item-retornoEm',
    display: 'Retorno',
    breakpoint: 6,
    visible: true,
  },
  {
    id: '30AnosContainer',
    content: 'item-30Anos',
    display: 'Economia em 30 anos',
    breakpoint: 6,
    visible: true,
  },
]

const initialTirVplContainers = [
  {
    id: 'TirContainer',
    content: 'item-Tir',
    display: 'TIR',
    breakpoint: 6,
    visible: true,
  },
  {
    id: 'VplContainer',
    content: 'item-Vpl',
    display: 'VPL',
    breakpoint: 6,
    visible: true,
  },
]

const initialInvesting = [
  {
    id: 'voceInvesteContainer',
    content: 'item-investing',
    display: 'Investimento',
    breakpoint: 12,
    visible: true,
  },
  {
    id: 'retornoSubCards',
    content: 'item-rretornoSubCards',
    display: 'Container de informações',
    breakpoint: 12,
    visible: true,
  },
  {
    id: 'tirVplContainers',
    content: 'item-tirVplContainers',
    display: 'Container de informações',
    breakpoint: 12,
    visible: true,
  },
  {
    id: 'RetornoContainer',
    content: 'item-Comparation',
    display: 'Comparação',
    breakpoint: 12,
    visible: true,
  },
]

const initialInvestingSections = [
  {
    id: 'allCards',
    content: 'item-allCards',
    display: 'Cards',
    breakpoint: 6,
    visible: true,
  },
  {
    id: 'retornoGrafico',
    content: 'item-retornoGrafico',
    display: 'Curva de retorno',
    breakpoint: 6,
    visible: true,
  },
]

const initialEqupmentsSections = [
  {
    id: 'equipmentsCard',
    content: 'item-equipmentsCard',
    display: 'Orçamento',
    breakpoint: 4,
    visible: true,
  },
  {
    id: 'ProducaoRetorno',
    content: 'item-ProducaoRetorno',
    display: 'Produção e consumo',
    breakpoint: 8,
    visible: true,
  },
]

const initialPaymentOptionsContainers = [
  {
    id: 'financingCard',
    content: 'item-financingCard',
    display: 'Financiamento',
    breakpoint: 8,
    visible: true,
  },
  {
    id: 'paymentOptionsCard',
    content: 'item-paymentOptionsCard',
    display: 'Forma de pagamento',
    breakpoint: 4,
    visible: true,
  },
]

const initialMoreInfosContainers = [
  {
    id: 'userOptionsCard',
    content: 'item-userOptionsCard',
    display: 'Interação',
    breakpoint: 4,
    visible: true,
  },
  {
    id: 'proposalInfosCard',
    content: 'item-proposalInfosCard',
    display: 'Informações da proposta',
    breakpoint: 8,
    visible: true,
  },
]

const initialPaymentOptionsSections = [
  {
    id: 'paymentsOptionsContainers',
    content: 'item-paymentOptionsContainers',
    display: 'Container de financiamento',
    breakpoint: 12,
    visible: true,
  },
  {
    id: 'moreInfosContainers',
    content: 'item-moreInfosContainers',
    display: 'Container de informações',
    breakpoint: 12,
    visible: true,
  },
]

const initialProposalSubInfosSections = [
  {
    id: 'scheduleCard',
    content: 'item-scheduleCard',
    breakpoint: 4,
    visible: true,
    display: 'Cronograma',
  },
  {
    id: 'premisessCard',
    content: 'item-premisessCard',
    breakpoint: 8,
    visible: true,
    display: 'Premissas do projeto',
  },
]

const initialProposalInfosSections = [
  {
    id: 'descriptionCard',
    content: 'item-descriptionCard',
    breakpoint: 12,
    visible: true,
    display: 'Descrição da proposta',
  },
  {
    id: 'quemSomosCard',
    content: 'item-quemSomos',
    breakpoint: 12,
    visible: true,
    display: 'Quem somos',
  },
  {
    id: 'subCards',
    content: 'item-subCards',
    breakpoint: 12,
    visible: true,
    display: 'Container de informações',
  },
  {
    id: 'constructionImagesCard',
    content: 'item-constructionImagesCard',
    breakpoint: 12,
    visible: true,
    display: 'Imagens de obras',
  },
  {
    id: 'conditionsCard',
    content: 'item-conditionsCard',
    breakpoint: 12,
    visible: true,
    display: 'Termos e condições',
  },
  {
    id: 'observations',
    content: 'item-observations',
    breakpoint: 12,
    visible: true,
    display: 'Observações',
  },
]

function PaymentsOptionsContainers({
  paymentOptionsContainers,
  setPaymentOptionsContainers,
  selectedQuote,
  colors,
}) {
  /* eslint eqeqeq: 0 */
  const classes = useStyles()
  const tokenRegister = localStorage.getItem('sessionToken')

  const [paymentMethods, setPaymentsMethods] = useState([])
  const [billetNumber, setBilletNumber] = useState()

  const getPayments = () => {
    API.get(`/proposal/quotation/payment_forms/${selectedQuote}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        const { methods } = res.data
        const methodsLength = Object.keys(methods).length
        if (methodsLength == 0) {
          const newArr = [...paymentOptionsContainers]
          const index = newArr.findIndex((item) => item.id == 'paymentOptionsCard')
          newArr[index].visible = false
          setPaymentOptionsContainers({ containers: newArr })
        }
        setPaymentsMethods(methods)
        if (methods.billet.billet_one) {
          setBilletNumber(1)
        }
        if (methods.billet.billet_two) {
          setBilletNumber(2)
        }
        if (methods.billet.billet_three) {
          setBilletNumber(3)
        }
        if (methods.billet.billet_four) {
          setBilletNumber(4)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getPayments()
    // eslint-disable-next-line
  }, [])

  return (
    <Grid style={{ height: '100%' }} container>
      {paymentOptionsContainers.map((container, index) => (
        <Grid
          item
          className={classes.paddingSection}
          xs={12}
          sm={12}
          md={
            paymentOptionsContainers.filter((item) => item.visible == true)
              .length == 1
              ? 12
              : 6
          }
          lg={
            paymentOptionsContainers.filter((item) => item.visible == true)
              .length == 1
              ? 12
              : 6
          }
          xl={
            paymentOptionsContainers.filter((item) => item.visible == true)
              .length == 1
              ? 12
              : container.breakpoint
          }
        >
          {container.visible && (
            <>
              {container.id == 'financingCard' && (
                <FinancingPaper
                  documentType="cpf"
                  isMobile={isMobile}
                  colors={colors}
                />
              )}
              {container.id == 'paymentOptionsCard' && (
                <PaymentFormsPaper
                  disabled
                  isMobile={isMobile}
                  colors={colors}
                  paymentMethods={paymentMethods}
                  billetNumber={billetNumber}
                />
              )}
            </>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

function MoreInfosContainers({ moreInfosContainers, proposal, colors }) {
  const classes = useStyles()

  return (
    <Grid style={{ height: '100%' }} container>
      {moreInfosContainers.map((container, index) => (
        <Grid
          item
          className={classes.paddingSection}
          xs={12}
          sm={12}
          md={
            moreInfosContainers.filter((item) => item.visible == true).length == 1
              ? 12
              : 6
          }
          lg={
            moreInfosContainers.filter((item) => item.visible == true).length == 1
              ? 12
              : 6
          }
          xl={
            moreInfosContainers.filter((item) => item.visible == true).length == 1
              ? 12
              : container.breakpoint
          }
        >
          {container.visible && (
            <>
              {container.id == 'userOptionsCard' && (
                <UserOptionsPaper isMobile={isMobile} colors={colors} />
              )}
              {container.id == 'proposalInfosCard' && (
                <ProposalInfoPaper
                  name={
                    proposal?.clients?.find(
                      (client) => client.id == proposal.proposal.customer_id,
                    )?.label
                  }
                  userName={proposal.userName}
                  isMobile={isMobile}
                  colors={colors}
                  proposal={proposal}
                />
              )}
            </>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

function PaymentSection({
  container,
  selectedQuote,
  paymentOptionsContainers,
  moreInfosContainers,
  proposal,
  setPaymentOptionsContainers,
  colors,
}) {
  const classes = useStyles()

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      className={classes.relative}
    >
      <div style={{ height: '100%' }}>
        {container.id == 'paymentsOptionsContainers' && (
          <PaymentsOptionsContainers
            paymentOptionsContainers={paymentOptionsContainers}
            selectedQuote={selectedQuote}
            proposal={proposal}
            setPaymentOptionsContainers={setPaymentOptionsContainers}
            colors={colors}
          />
        )}
        {container.id == 'moreInfosContainers' && (
          <MoreInfosContainers
            moreInfosContainers={moreInfosContainers}
            proposal={proposal}
            colors={colors}
          />
        )}
      </div>
    </Grid>
  )
}

function EquipmentsSection({
  container,
  stateEquipmentsSections,
  equipmentsList,
  selectedQuote,
  proposal,
  colors,
}) {
  const classes = useStyles()
  const tokenRegister = localStorage.getItem('sessionToken')
  const [budgets, setBudgets] = useState([])

  const getBudgets = () => {
    API.get(`/proposal/list/budgets/${selectedQuote}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        setBudgets(res.data)
      })
      .catch((err) => {})
  }

  useEffect(() => {
    getBudgets()
    // eslint-disable-next-line
  }, [])

  return (
    <Grid
      item
      className={classes.paddingSection}
      xs={12}
      sm={12}
      md={
        stateEquipmentsSections.filter((item) => item.visible == true).length == 1
          ? 12
          : 6
      }
      lg={
        stateEquipmentsSections.filter((item) => item.visible == true).length == 1
          ? 12
          : 6
      }
      xl={
        stateEquipmentsSections.filter((item) => item.visible == true).length == 1
          ? 12
          : container.breakpoint
      }
    >
      <div style={{ height: '100%' }}>
        {container.id == 'equipmentsCard' && (
          <BudgetPaper
            isMobile={isMobile}
            colors={colors}
            budgets={budgets}
            showEquips={equipmentsList.visible}
          />
        )}
        {container.id == 'ProducaoRetorno' && (
          <PotencyConsumptionPaper
            downSm={isMobile}
            isMobile={isMobile}
            colors={colors}
            proposal={proposal}
            selectedQuote={selectedQuote}
          />
        )}
      </div>
    </Grid>
  )
}

function InvestingSection({
  container,
  investingContainers,
  selectedQuote,
  proposal,
  size,
  tirVplContainers,
  retornoSubCards,
  colors,
  investingSections,
}) {
  const classes = useStyles()
  const tokenRegister = localStorage.getItem('sessionToken')

  const [budgetEdit, setBudgetEdit] = useState([])
  const [returnData, setReturnData] = useState()

  const handleShowRoi = (sizing, values) => {
    API.get(`/proposal/quotation/roi/calculate/${selectedQuote}`, {
      headers: {
        token: tokenRegister,
      },
    })
      .then((res) => {
        setReturnData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleGetBudget = (id) => {
    API.get(`/proposal/list/budgets/${id}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        setBudgetEdit(res.data)
        handleShowRoi(
          proposal.sizings.find(
            (sizing) => sizing.id
              == proposal.quotes.find((quote) => quote.quotation.id == selectedQuote)
                .dimensioning.id,
          ),
          res.data,
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    handleGetBudget(selectedQuote)
    // eslint-disable-next-line
  }, [])

  return (
    <Grid
      item
      className={classes.paddingSection}
      xs={12}
      md={12}
      lg={12}
      xl={
        investingSections.filter((container) => container.visible).length != 1
        || (investingContainers.find(
          (container) => container.id == 'RetornoContainer',
        ).visible
          && tirVplContainers.filter((container) => container.visible).length != 0
          && retornoSubCards.filter((container) => container.visible).length != 0)
          ? 6
          : 12
      }
    >
      <div style={{ height: '100%' }}>
        {container.id == 'allCards' && (
          isMobile && returnData?.dadosConsolidados ? (
            <>
              <InvestingPaperMobile
                investingContainers={investingContainers}
                returnData={returnData}
                tirVplContainers={tirVplContainers}
                retornoSubCards={retornoSubCards}
                colors={colors}
              />
              {investingContainers.find(
                (contain) => contain.id == 'RetornoContainer',
              ).visible && (
              <Paper style={{ marginTop: '8px', padding: '16px' }}>
                <div style={{ marginBottom: '16px' }}>
                  <Typography
                    type="link_x_small"
                    color="grayscale"
                    colorWeight="600"
                    style={{
                      margin: '0px',
                      color: colors && colors.texts && colors.texts,
                    }}
                  >
                    A economia equivale a:
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px',
                  }}
                >
                  <CarFrontIcon
                    width="32"
                    height="32"
                    color={
                          colors && colors.primary_color
                            ? colors.primary_color
                            : palette.primary[300]
                        }
                  />
                  <Typography
                    type="link_small"
                    color="primary"
                    colorWeight="300"
                    style={{
                      margin: '0px',
                      marginLeft: '24px',
                      color:
                            colors
                            && colors.primary_color
                            && colors.primary_color,
                    }}
                  >
                    {returnData && returnData.carros ? (
                      <>
                        {returnData.carros.toFixed(0)}
                        {' '}
                        carros
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px',
                  }}
                >
                  <DevicePhoneIcon
                    width="32"
                    height="32"
                    color={
                          colors && colors.primary_color
                            ? colors.primary_color
                            : palette.primary[300]
                        }
                  />
                  <Typography
                    type="link_small"
                    color="primary"
                    colorWeight="300"
                    style={{
                      margin: '0px',
                      marginLeft: '24px',
                      color:
                            colors
                            && colors.primary_color
                            && colors.primary_color,
                    }}
                  >
                    {returnData && returnData.celulares ? (
                      <>
                        {returnData.celulares.toFixed(0)}
                        {' '}
                        celulares
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px',
                  }}
                >
                  <DeviceDesktopIcon
                    width="32"
                    height="32"
                    color={
                          colors && colors.primary_color
                            ? colors.primary_color
                            : palette.primary[300]
                        }
                  />
                  <Typography
                    type="link_small"
                    color="primary"
                    colorWeight="300"
                    style={{
                      margin: '0px',
                      marginLeft: '24px',
                      color:
                            colors
                            && colors.primary_color
                            && colors.primary_color,
                    }}
                  >
                    {returnData && returnData.tvs ? (
                      <>
                        {returnData.tvs.toFixed(0)}
                        {' '}
                        TVs
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </div>
              </Paper>
              )}
            </>
          ) : (
            <Grid style={{ height: '100%' }} container spacing={1}>
              {investingContainers.map((container, index) => (
                container.visible && (
                  ((container.id == 'retornoSubCards'
                          && retornoSubCards.filter((container) => container.visible)
                            .length != 0)
                          || (container.id == 'tirVplContainers'
                            && tirVplContainers.filter(
                              (container) => container.visible,
                            ).length != 0)
                          || (container.id != 'tirVplContainers'
                            && container.id != 'retornoSubCards')) && (
                            <InvestingContainer
                              investingContainers={investingContainers}
                              container={container}
                              index={index}
                              proposal={proposal}
                              size={size}
                              tirVplContainers={tirVplContainers}
                              retornoSubCards={retornoSubCards}
                              budgetEdit={budgetEdit}
                              returnData={returnData}
                              handleGetBudget={handleGetBudget}
                              colors={colors}
                            />
                  )
                )
              ))}
            </Grid>
          )
        )}
        {container.id == 'retornoGrafico' && (
          <ReturnGraphPaper
            isMobile={isMobile}
            returnData={returnData}
            colors={colors}
          />
        )}
      </div>
    </Grid>
  )
}

function RetornoSubCards({ retornoSubCards, returnData, colors }) {
  const classes = useStyles()

  return (
    <Grid style={{ height: '100%' }} container>
      {retornoSubCards.map((container, index) => (
        container.visible && (
        <Grid
          item
          className={
                index == 0
                  ? classes.paddingSectionMarginRight
                  : classes.paddingSectionMarginLeft
              }
          xs={12}
          sm={12}
          md={
                retornoSubCards.filter((item) => item.visible == true).length == 1
                  ? 12
                  : 6
              }
          lg={
                retornoSubCards.filter((item) => item.visible == true).length == 1
                  ? 12
                  : 6
              }
          xl={
                retornoSubCards.filter((item) => item.visible == true).length == 1
                  ? 12
                  : container.breakpoint
              }
        >
          {container.visible && (
          <>
            {container.id == 'retornoEmContainer' && (
            <InfoPaper
              colors={colors}
              icon={(
                <TimeIcon
                  height="48"
                  width="48"
                  color={
                            colors ? colors.primary_color : palette.primary[300]
                          }
                />
                      )}
              title="Tenha seu retorno em"
              text={
                        returnData?.payback && `${returnData?.payback} anos`
                      }
            />
            )}
            {container.id == '30AnosContainer' && (
            <InfoPaper
              colors={colors}
              icon={(
                <DollarIcon
                  height="48"
                  width="48"
                  color={
                            colors ? colors.primary_color : palette.primary[300]
                          }
                />
                      )}
              title={`Em ${
                returnData?.anos ? returnData?.anos : 25
              } anos você economiza`}
              text={
                        returnData?.dadosConsolidados
                        && `${returnData?.dadosConsolidados[
                          returnData.dadosConsolidados.length - 1
                        ].fluxoAcumuladoAnual?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}`
                      }
            />
            )}
          </>
          )}
        </Grid>
        )
      ))}
    </Grid>
  )
}

function TirVplContainers({ tirVplContainers, returnData, colors }) {
  const classes = useStyles()

  return (
    <Grid style={{ height: '100%' }} container>
      {tirVplContainers.map((container, index) => (
        container.visible && (
        <Grid
          item
          className={
                index == 0
                  ? classes.paddingSectionMarginRight
                  : classes.paddingSectionMarginLeft
              }
          xs={12}
          sm={12}
          md={
                tirVplContainers.filter((item) => item.visible == true).length
                == 1
                  ? 12
                  : 6
              }
          lg={
                tirVplContainers.filter((item) => item.visible == true).length
                == 1
                  ? 12
                  : 6
              }
          xl={
                tirVplContainers.filter((item) => item.visible == true).length
                == 1
                  ? 12
                  : container.breakpoint
              }
        >
          {container.visible && (
          <>
            {container.id == 'TirContainer' && (
            <InfoPaper
              colors={colors}
              icon={(
                <ChartBarIcon
                  height="48"
                  width="48"
                  color={
                            colors ? colors.primary_color : palette.primary[300]
                          }
                />
                      )}
              title="TIR - Taxa interna de retorno"
              text={returnData?.tir && `${returnData?.tir.toFixed(2)}%`}
            />
            )}
            {container.id == 'VplContainer' && (
            <InfoPaper
              colors={colors}
              icon={(
                <RefreshIcon
                  height="48"
                  width="48"
                  color={
                            colors ? colors.primary_color : palette.primary[300]
                          }
                />
                      )}
              title="VPL - Valor presente líquido"
              text={
                        returnData?.vpl
                        && returnData?.vpl.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                      }
            />
            )}
          </>
          )}
        </Grid>
        )
      ))}
    </Grid>
  )
}

function InvestingContainer({
  container,
  index,
  investingContainers,
  selectedQuote,
  proposal,
  size,
  tirVplContainers,
  retornoSubCards,
  returnData,
  colors,
}) {
  const classes = useStyles()

  return (
    <Grid
      item
      className={classes.paddingSection}
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={container.breakpoint}
    >
      <div style={{ height: '100%' }}>
        {container.id == 'retornoSubCards' && (
          <RetornoSubCards
            container={container}
            index={index}
            investingContainers={investingContainers}
            selectedQuote={selectedQuote}
            proposal={proposal}
            size={size}
            retornoSubCards={retornoSubCards}
            returnData={returnData}
            colors={colors}
          />
        )}
        {container.id == 'tirVplContainers' && (
          <TirVplContainers
            container={container}
            index={index}
            investingContainers={investingContainers}
            selectedQuote={selectedQuote}
            proposal={proposal}
            size={size}
            tirVplContainers={tirVplContainers}
            returnData={returnData}
            colors={colors}
          />
        )}
        {container.id == 'voceInvesteContainer' && (
          <InfoPaper
            isMobile={isMobile}
            colors={colors}
            icon={(
              <CashMoneyIcon
                height="48"
                width="48"
                color={colors ? colors.primary_color : palette.primary[300]}
              />
            )}
            title="Você investe"
            text={
              returnData?.custoTotalProjeto
              && parseFloat(returnData?.custoTotalProjeto)?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' },
              )
            }
          />
        )}
        {container.id == 'RetornoContainer' && (
          <ReturnPaper
            isMobile={isMobile}
            colors={colors}
            returnData={returnData}
          />
        )}
      </div>
    </Grid>
  )
}

function Container({
  container,
  investingContainers,
  investingSections,
  stateEquipmentsSections,
  statePaymentSections,
  selectedQuote,
  proposal,
  size,
  paymentOptionsContainers,
  moreInfosContainers,
  tirVplContainers,
  retornoSubCards,
  setPaymentOptionsContainers,
  colors,
}) {
  const schedules = proposal?.extraConfigs?.schedules?.data
    ? proposal.extraConfigs.schedules.data
    : [{ title: '', weeks: '' }]
  const equipmentsList = {
    id: 'equipmentsList',
    content: 'item-equipmentsList',
    visible: true,
    display: 'Equipamentos',
  }

  return (
    <Grid
      item
      xs={12}
      style={{
        padding: '16px 0px !important',
        backgroundColor: colors ? colors.background : '#F7F7FC',
        border: 'none',
        paddingTop: '16px',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 10,
      }}
    >
      {container.id == 'investingContainer' && (
        <Grid
          style={{
            position: 'relative',
            backgroundColor: colors ? colors.background : '#F7F7FC',
            borderRadius: '8px',
          }}
          container
        >
          {investingSections.map((container, index) => (
            container.visible && (
            <InvestingSection
              selectedQuote={selectedQuote}
              investingSections={investingSections}
              container={container}
              index={index}
              investingContainers={investingContainers}
              proposal={proposal}
              size={size}
              tirVplContainers={tirVplContainers}
              retornoSubCards={retornoSubCards}
              colors={colors}
            />
            )
          ))}
        </Grid>
      )}
      {container.id == 'equipmentsContainer' && (
        <Grid
          style={{
            position: 'relative',
            backgroundColor: colors ? colors.background : '#F7F7FC',
            borderRadius: '8px',
          }}
          container
        >
          {stateEquipmentsSections.map((container, index) => (
            container.visible && (
            <EquipmentsSection
              selectedQuote={selectedQuote}
              equipmentsList={equipmentsList}
              stateEquipmentsSections={stateEquipmentsSections}
              container={container}
              index={index}
              size={size}
              proposal={proposal}
              colors={colors}
            />
            )
          ))}
        </Grid>
      )}
      {container.id == 'paymentsOptionsContainer' && (
        <Grid
          style={{
            position: 'relative',
            backgroundColor: colors ? colors.background : '#F7F7FC',
            borderRadius: '8px',
          }}
          container
        >
          {statePaymentSections.map((container, index) => (
            container.visible && (
            <PaymentSection
              selectedQuote={selectedQuote}
              statePaymentSections={statePaymentSections}
              container={container}
              index={index}
              size={size}
              paymentOptionsContainers={paymentOptionsContainers}
              moreInfosContainers={moreInfosContainers}
              proposal={proposal}
              setPaymentOptionsContainers={setPaymentOptionsContainers}
              colors={colors}
            />
            )
          ))}
        </Grid>
      )}
      {container.id == 'scheduleCard' && (
        <Grid
          // onClick={(e) => (isViewing == container.id) && e.stopPropagation()}
          style={{
            position: 'relative',
            backgroundColor: colors ? colors.background : '#F7F7FC',
            borderRadius: '8px',
          }}
          container
        >
          <Grid
            item
            style={{
              padding: '4px',
              position: 'relative',
            }}
            xs={12}
          >
            <SchedulePaper
              isMobile={isMobile}
              schedules={schedules}
              colors={colors}
            />
          </Grid>
        </Grid>
      )}

      {container.id == 'constructionImagesCard' && (
        <Grid
          // onClick={(e) => (isViewing == container.id) && e.stopPropagation()}
          style={{
            position: 'relative',
            backgroundColor: colors ? colors.background : '#F7F7FC',
            borderRadius: '8px',
          }}
          container
        >
          <Grid
            item
            style={{
              padding: '4px',
              position: 'relative',
            }}
            xs={12}
          >
            <ImagesPaper
              isMobile={isMobile}
              proposal={proposal}
              colors={colors}
            />
          </Grid>
        </Grid>
      )}
      {Number.isInteger(container.id) && (
        <Grid
          // onClick={(e) => (isViewing == container.id) && e.stopPropagation()}
          style={{
            position: 'relative',
            backgroundColor: colors ? colors.background : '#F7F7FC',
            borderRadius: '8px',
          }}
          container
        >
          <Grid
            item
            style={{
              padding: '4px',
              position: 'relative',
            }}
            xs={12}
          >
            {proposal.extraConfigs.extra_configs
              .find((item) => item.id == container.id)
              ?.title?.split('/')[1] ? (
                <img
                  alt="Extra config"
                  style={{ width: '100%', height: 'auto' }}
                  src={
                  proposal.extraConfigs.extra_configs.find(
                    (item) => item.id == container.id,
                  )?.content
                }
                />
              ) : (
                proposal.extraConfigs.extra_configs
                  .find((item) => item.id == container.id)
                  ?.content.includes('.s3') ? (
                    <ImagePaper
                      image={
                      proposal.extraConfigs.extra_configs.find(
                        (item) => item.id == container.id,
                      )?.content
                    }
                      title={
                      proposal.extraConfigs.extra_configs.find(
                        (item) => item.id == container.id,
                      )?.title
                    }
                      colors={colors}
                      isMobile={isMobile}
                    />
                  ) : (
                    <TextPaper
                      text={
                      proposal.extraConfigs.extra_configs.find(
                        (item) => item.id == container.id,
                      )?.content
                    }
                      title={
                      proposal.extraConfigs.extra_configs.find(
                        (item) => item.id == container.id,
                      )?.title
                    }
                      colors={colors}
                      isMobile={isMobile}
                    />
                  )
              )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const ContainerList = React.memo(({
  containers,
  investingContainers,
  investingSections,
  setState,
  stateEquipmentsSections,
  statePaymentSections,
  ProposalInfosSections,
  selectedQuote,
  proposal,
  size,
  paymentOptionsContainers,
  moreInfosContainers,
  ProposalSubInfosSections,
  tirVplContainers,
  retornoSubCards,
  colors,
}) => containers.map((container, index) => (
  container.visible && (
    <Container
      container={container}
      index={index}
      key={container.id}
      allContainers={containers}
      setState={setState}
      investingContainers={investingContainers}
      investingSections={investingSections}
      stateEquipmentsSections={stateEquipmentsSections}
      statePaymentSections={statePaymentSections}
      ProposalInfosSections={ProposalInfosSections}
      selectedQuote={selectedQuote}
      proposal={proposal}
      size={size}
      paymentOptionsContainers={paymentOptionsContainers}
      moreInfosContainers={moreInfosContainers}
      ProposalSubInfosSections={ProposalSubInfosSections}
      tirVplContainers={tirVplContainers}
      retornoSubCards={retornoSubCards}
      colors={colors}
    />
  )
)))

function FullscreenProposal(props) {
  const { setPropostaStep, proposal, size } = props

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const tokenRegister = localStorage.getItem('sessionToken')

  const [modalInfoFluxo, setModalInfoFLuxo] = useState(false)
  const [selectedQuote, setSelectedQuote] = useState(
    proposal.quotes[0].quotation.id,
  )

  const logo = proposal.proposal.logo ? proposal.proposal.logo : ''
  const backgroundImage = proposal?.extraConfigs?.cover_image?.data?.url
    ? proposal?.extraConfigs?.cover_image?.data?.url
    : ''
  const colors = proposal.extraConfigs.color_configs
    ? proposal.extraConfigs.color_configs
    : ''

  const classes = useStyles()

  const [state, setState] = useStateWithCallbackLazy({
    containers: initial,
  })

  const [stateInvesting, setStateInvesting] = useStateWithCallbackLazy({
    containers: initialInvesting,
  })

  const [
    stateInvestingSections,
    setStateInvestingSections,
  ] = useStateWithCallbackLazy({
    containers: initialInvestingSections,
  })

  const [
    stateEquipmentsSections,
    setStateEquipmentsSections,
  ] = useStateWithCallbackLazy({
    containers: initialEqupmentsSections,
  })

  const [
    statePaymentSections,
    setStatePaymentSections,
  ] = useStateWithCallbackLazy({
    containers: initialPaymentOptionsSections,
  })

  const ProposalInfosSections = {
    containers: initialProposalInfosSections,
  }

  const ProposalSubInfosSections = {
    containers: initialProposalSubInfosSections,
  }

  const [
    paymentOptionsContainers,
    setPaymentOptionsContainers,
  ] = useStateWithCallbackLazy({
    containers: initialPaymentOptionsContainers,
  })

  const [
    moreInfosContainers,
    setMoreInfosContainers,
  ] = useStateWithCallbackLazy({
    containers: initialMoreInfosContainers,
  })

  const [tirVplContainers, setTirVplContainers] = useStateWithCallbackLazy({
    containers: initialTirVplContainers,
  })

  const [retornoSubCards, setRetornoSubCards] = useStateWithCallbackLazy({
    containers: initialRetornoSubCards,
  })

  useEffect(() => {
    API.get(`/proposal/drag-and-drop/${proposal.proposal.id}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        if (res.data[0]) {
          setState(res.data[0])
          setStateInvesting(res.data[1])
          setStateInvestingSections(res.data[2])
          setStateEquipmentsSections(res.data[3])
          setStatePaymentSections(res.data[4])
          setPaymentOptionsContainers(res.data[5])
          setMoreInfosContainers(res.data[6])
          setTirVplContainers(res.data[7])
          setRetornoSubCards(res.data[8])
        }
      })
      .catch((res) => {})
      // eslint-disable-next-line
  }, [])

  const { userName } = proposal

  const seeResponse = (res) => {
    setSelectedQuote(Number(res))
  }

  return downSm ? (
    <div
      style={{
        backgroundColor: colors && colors.background,
        height: 'calc(100% - 74px)',
      }}
      className={classes.paperRendered}
    >
      <ClientPaper
        backgroundImage={backgroundImage}
        isMobile={isMobile}
        logo={logo}
        userName={userName}
        proposal={proposal}
        selectedQuote={selectedQuote}
        setSelectedQuote={seeResponse}
        colors={colors}
      />
      {isMobile && <Spacer size="96" direction="horizontal" />}
      {!isMobile && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={{
              marginTop: '16px',
              marginBottom: '16px',
              backgroundColor: colors && colors.primary_color,
              border: colors && `2px solid ${colors.primary_color}`,
              zIndex: 100,
            }}
          >
            Financiar projeto
          </Button>
        </div>
      )}
      <ContainerList
        investingSections={stateInvestingSections.containers}
        investingContainers={stateInvesting.containers}
        stateEquipmentsSections={stateEquipmentsSections.containers}
        statePaymentSections={statePaymentSections.containers}
        ProposalInfosSections={ProposalInfosSections.containers}
        containers={state.containers}
        selectedQuote={selectedQuote}
        proposal={proposal}
        size={size}
        paymentOptionsContainers={paymentOptionsContainers.containers}
        moreInfosContainers={moreInfosContainers.containers}
        ProposalSubInfosSections={ProposalSubInfosSections.containers}
        tirVplContainers={tirVplContainers.containers}
        retornoSubCards={retornoSubCards.containers}
        setPaymentOptionsContainers={setPaymentOptionsContainers}
        colors={colors}
      />
      <CashFlowModal
        isMobile={isMobile}
        retornoConfig={25}
        fluxos={
          proposal.sizings.find(
            (sizing) => sizing.id
              == proposal.quotes.find((quote) => quote.quotation.id == selectedQuote)
                .dimensioning.id,
          ).dadosConsolidados
        }
        modalInfoFluxo={modalInfoFluxo}
        setModalInfoFLuxo={setModalInfoFLuxo}
      />
    </div>
  ) : (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open
      onClose={() => setPropostaStep('structuringProposal')}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <div
          style={{ backgroundColor: colors && colors.background }}
          className={classes.paperRendered}
        >
          <FullScreenExpandResizeIcon
            width={downSm ? '48' : '60'}
            onClick={() => setPropostaStep('structuringProposal')}
            style={{
              color: palette.primary[300],
              position: 'fixed',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
              zIndex: 1000,
              padding: '10px',
              borderRadius: '50%',
              boxShadow: '0 0 8px rgb(6 62 249 / 50%)',
              backgroundColor: palette.white,
              height: downSm && '48px',
            }}
          />
          <ClientPaper
            backgroundImage={backgroundImage}
            isMobile={isMobile}
            logo={logo}
            userName={userName}
            proposal={proposal}
            selectedQuote={selectedQuote}
            setSelectedQuote={seeResponse}
            colors={colors}
          />
          {isMobile && <Spacer size="96" direction="horizontal" />}
          {!isMobile && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{
                  marginTop: '16px',
                  marginBottom: '16px',
                  backgroundColor: colors && colors.primary_color,
                  border: colors && `2px solid ${colors.primary_color}`,
                  zIndex: 100,
                }}
              >
                Financiar projeto
              </Button>
            </div>
          )}
          <ContainerList
            investingSections={stateInvestingSections.containers}
            investingContainers={stateInvesting.containers}
            stateEquipmentsSections={stateEquipmentsSections.containers}
            statePaymentSections={statePaymentSections.containers}
            ProposalInfosSections={ProposalInfosSections.containers}
            containers={state.containers}
            selectedQuote={selectedQuote}
            proposal={proposal}
            size={size}
            paymentOptionsContainers={paymentOptionsContainers.containers}
            moreInfosContainers={moreInfosContainers.containers}
            ProposalSubInfosSections={ProposalSubInfosSections.containers}
            tirVplContainers={tirVplContainers.containers}
            retornoSubCards={retornoSubCards.containers}
            setPaymentOptionsContainers={setPaymentOptionsContainers}
            colors={colors}
          />
          <CashFlowModal
            isMobile={isMobile}
            retornoConfig={25}
            fluxos={
              proposal.sizings.find(
                (sizing) => sizing.id
                  == proposal.quotes.find(
                    (quote) => quote.quotation.id == selectedQuote,
                  ).dimensioning.id,
              ).dadosConsolidados
            }
            modalInfoFluxo={modalInfoFluxo}
            setModalInfoFLuxo={setModalInfoFLuxo}
          />
        </div>
      </Fade>
    </Modal>
  )
}

export default withSize()(FullscreenProposal)
