import { useQuery } from '@tanstack/react-query'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import {
  type ICreateProjectResponse,
  type IDimensioningGETResponse,
} from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'
import { useHistory } from 'react-router-dom'
import { getAllCustomers } from 'utils/requests'
import { useDispatch } from 'react-redux'
import * as ProposalAction from 'store/actions'

export function useModalSelectClient() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { dimensioningId, quotationSelected } = useEquipmentContainerStore()
  const { data } = useQuery({
    queryKey: ['GET_USER_CLIENTS'],
    queryFn: () => getAllCustomers(),
  })

  const { data: dimensioningData } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const proposal = {
    ...dimensioningData?.proposal,
    quotes: [{ quotation: quotationSelected }],
  }

  const { data: dimensioningResponse } =
    useObserveQuery<IDimensioningGETResponse>([
      GET_DIMENSIONING,
      dimensioningId,
    ])

  const quotations = dimensioningResponse?.quotations ?? []

  function onCreateProject(data: ICreateProjectResponse) {
    dispatch(
      ProposalAction.setOriginLead(data.quotation?.originLead ?? 'INTEGRADOR'),
    )
    dispatch(ProposalAction.SaveProposalRedux(data.proposal))
    dispatch(ProposalAction.SaveSizingRedux(data.dimensioning_properties))
    dispatch(
      ProposalAction.SaveActiveSizingRedux(data.dimensioning_properties.id),
    )
    for (const quotation of quotations) {
      dispatch(
        ProposalAction.SaveQuoteRedux({
          dimensioning: data.dimensioning,
          kit: data.kit,
          quotation,
        }),
      )
    }

    history.push('/dimensionar')
  }

  return {
    data,
    proposal,
    onCreateProject,
  }
}
