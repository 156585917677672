import { format } from 'date-fns'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'
import { useMemo } from 'react'

interface IUseProductName {
  product?: IQuotationProductDTO | null
  availabilityLabel?: string
}

export function useProductName({
  product,
  availabilityLabel = 'Previsão de disponibilidade',
}: IUseProductName) {
  function separateProductName(name: string, available: string | null) {
    const [productName, availability] = String(name).split(
      ' - Disp. em estoque:',
    )

    const availableDate = available
      ? format(new Date(available), 'dd/MM/yyyy')
      : availability

    const availabilityMessage = availableDate
      ? `${availabilityLabel}: ${availableDate}`
      : 'Disponível em estoque'

    return {
      productName,
      availabilityMessage,
    }
  }

  const { productName, availabilityMessage } = useMemo(() => {
    if (product) {
      return separateProductName(product.titulo, product.available_in)
    }

    return {
      productName: '',
      availabilityMessage: '',
    }
  }, [product])

  return {
    productName,
    availabilityMessage,
    separateProductName,
  }
}
