import React from 'react'
import * as S from './styles'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'

interface IProductListProps {
  products: IQuotationProductDTO[]
}
const ProductList: React.FC<IProductListProps> = ({ products = [] }) => {
  const lastProductIndex = products.length - 1

  return (
    <>
      <S.Title className="title">Produtos</S.Title>
      <S.ProductListWrapper>
        {products.map((product, index) => (
          <S.ProductWrapper
            className="productContainer"
            key={product.id}
            noDivider={index === lastProductIndex}
          >
            <img src={product?.url} alt={product?.titulo} />
            <span className="productTitle">{product?.titulo}</span>
            <span>
              <span className="quantity">{product?.qtde}</span> <span>un</span>
            </span>
          </S.ProductWrapper>
        ))}
      </S.ProductListWrapper>
    </>
  )
}

export { ProductList }
