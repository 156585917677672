import React, { useEffect } from 'react'
import { Hidden, IconButton, Tooltip, useMediaQuery } from '@material-ui/core'
import { Typography } from 'components'
import { Spacer, Paper, Button, BarChart } from '@77sol/core'
import { ArrowChevronForwardIcon, EditIcon } from '@77sol/icons/dist'
import palette from 'app_palette'

function InvestimentoRetornoCard(props) {
  const {
    onlyShow,
    returnData,
    quotePanelEditing,
    setQuotePanelEditing,
    isFromProposalList,
    noPadding,
  } = props

  /* eslint eqeqeq: 0 */
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {}, [])

  return returnData ? (
    <Paper
      style={{
        backgroundColor:
          quotePanelEditing == 'investmentReturn' &&
          !onlyShow &&
          palette.primary[300],
        borderRadius: '16px',
        padding: noPadding ? '0px' : '24px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          type={
            downSm
              ? 'link_small'
              : upXl || isFromProposalList
              ? 'link_medium'
              : 'text_small'
          }
          color={
            quotePanelEditing == 'investmentReturn' && !onlyShow
              ? 'white'
              : 'grayscale'
          }
          colorWeight="700"
        >
          Retorno de investimento
        </Typography>
        {!onlyShow && (
          <>
            {quotePanelEditing == 'investmentReturn' ? (
              <ArrowChevronForwardIcon width="16" color={palette.white} />
            ) : (
              <>
                {downSm ? (
                  <Tooltip title="Editar">
                    <IconButton
                      id="quoteEdit_button_investmentReturn"
                      aria-label="Editar"
                      onClick={() => setQuotePanelEditing('investmentReturn')}
                    >
                      <EditIcon width="24px" color={palette.grayscale[700]} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    <Hidden xlUp>
                      <Tooltip title="Editar">
                        <IconButton
                          id="quoteEdit_button_investmentReturn"
                          aria-label="Editar"
                          onClick={() =>
                            setQuotePanelEditing('investmentReturn')
                          }
                        >
                          <EditIcon
                            width="24px"
                            color={palette.grayscale[700]}
                          />
                        </IconButton>
                      </Tooltip>
                    </Hidden>
                    <Hidden lgDown>
                      <Button
                        id="quoteEdit_button_investmentReturn"
                        variant="outlined"
                        noMargin
                        size="small"
                        onClick={() => setQuotePanelEditing('investmentReturn')}
                      >
                        Editar
                      </Button>
                    </Hidden>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Spacer
        size={upXl || isFromProposalList ? '4' : '10'}
        direction="horizontal"
      />
      <div>
        <Typography
          type="text_x_small"
          color={
            quotePanelEditing == 'investmentReturn' && !onlyShow
              ? 'white'
              : 'grayscale'
          }
          colorWeight="600"
        >
          Periodo de retorno
        </Typography>
        <Spacer size="10" direction="horizontal" />
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography
            type={
              downSm || !upXl || isFromProposalList
                ? 'display_x_small_bold'
                : 'display_small_bold'
            }
            color={
              quotePanelEditing == 'investmentReturn' && !onlyShow
                ? 'white'
                : 'grayscale'
            }
            colorWeight="700"
          >
            {returnData?.payback}
          </Typography>
          <Spacer size="4" direction="vertical" />
          <Typography
            type="text_x_small"
            color={
              quotePanelEditing == 'investmentReturn' && !onlyShow
                ? 'white'
                : 'grayscale'
            }
            colorWeight="600"
          >
            anos
          </Typography>
        </div>
      </div>
      <Spacer size="24" direction="horizontal" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography
          type={downSm ? 'link_medium' : 'link_large'}
          color={
            quotePanelEditing == 'investmentReturn' && !onlyShow
              ? 'white'
              : 'grayscale'
          }
          colorWeight="700"
        >
          Investimento x Retorno
        </Typography>
      </div>
      <Spacer size="4" direction="horizontal" />
      <div
        style={{
          display: 'flex',
          textAlign:
            (quotePanelEditing && !upXl) || isFromProposalList
              ? 'left'
              : 'center',
          flexDirection: quotePanelEditing && !upXl ? 'column' : 'row',
        }}
      >
        <div style={{ width: '50%' }}>
          <Typography
            type={downSm ? 'text_small' : 'text_medium'}
            color={
              quotePanelEditing == 'investmentReturn' && !onlyShow
                ? 'white'
                : 'grayscale'
            }
            colorWeight="700"
          >
            1º ano
          </Typography>
          {((!quotePanelEditing && !upXl) || isFromProposalList) && (
            <Spacer size="8" direction="horizontal" />
          )}
          <Typography
            type={downSm ? 'link_small' : 'text_medium'}
            color={
              returnData?.dadosConsolidados[0]?.fluxoAcumuladoAnual > 0
                ? 'green'
                : 'red'
            }
            colorWeight={
              quotePanelEditing == 'investmentReturn' && !onlyShow ? 200 : 300
            }
            style={{
              width:
                (quotePanelEditing && !upXl) || isFromProposalList
                  ? 'max-content'
                  : 'auto',
            }}
          >
            {returnData?.dadosConsolidados[0]?.fluxoAcumuladoAnual?.toLocaleString(
              'pt-br',
              { style: 'currency', currency: 'BRL' },
            )}
          </Typography>
        </div>
        <div style={{ width: '50%' }}>
          <Typography
            type={downSm ? 'text_small' : 'text_medium'}
            color={
              quotePanelEditing == 'investmentReturn' && !onlyShow
                ? 'white'
                : 'grayscale'
            }
            colorWeight="700"
          >
            {returnData?.anos}º ano
          </Typography>
          {((!quotePanelEditing && !upXl) || isFromProposalList) && (
            <Spacer size="8" direction="horizontal" />
          )}
          <Typography
            type={downSm ? 'link_small' : 'text_medium'}
            color={
              returnData?.dadosConsolidados[24]?.fluxoAcumuladoAnual > 0
                ? 'green'
                : 'red'
            }
            colorWeight={
              quotePanelEditing == 'investmentReturn' && !onlyShow ? 200 : 300
            }
            style={{
              width:
                (quotePanelEditing && !upXl) || isFromProposalList
                  ? 'max-content'
                  : 'auto',
            }}
          >
            {returnData?.dadosConsolidados[24]?.fluxoAcumuladoAnual?.toLocaleString(
              'pt-br',
              { style: 'currency', currency: 'BRL' },
            )}
          </Typography>
        </div>
      </div>
      {returnData && returnData?.dadosConsolidados && (
        <BarChart
          returnData={returnData}
          selected={quotePanelEditing == 'investmentReturn' && !onlyShow}
          whiteLegend={!!(quotePanelEditing == 'investmentReturn' && !onlyShow)}
        />
      )}
      {onlyShow && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Spacer size="16" direction="horizontal" />
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              flexDirection: downSm
                ? 'column'
                : upXl || isFromProposalList
                ? 'row'
                : 'column',
            }}
          >
            <Typography
              type={
                downSm || !upXl || isFromProposalList
                  ? 'text_x_small'
                  : 'text_medium'
              }
              color="grayscale"
              colorWeight="500"
            >
              Retorno de investimento
            </Typography>
            <Spacer size="8" direction="vertical" />
            <Typography
              type={
                downSm || !upXl || isFromProposalList
                  ? 'link_medium'
                  : 'link_small'
              }
              color="grayscale"
              colorWeight="700"
            >
              {returnData?.dadosConsolidados[24]?.fluxoAcumuladoAnual?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' },
              )}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              flexDirection: downSm ? 'column' : upXl ? 'row' : 'column',
            }}
          >
            <Typography
              type={
                downSm || !upXl || isFromProposalList
                  ? 'text_x_small'
                  : 'text_medium'
              }
              color="grayscale"
              colorWeight="500"
            >
              Taxa interna de retorno
            </Typography>
            <Spacer size="8" direction="vertical" />
            <Typography
              type={
                downSm || !upXl || isFromProposalList
                  ? 'link_medium'
                  : 'link_small'
              }
              color="grayscale"
              colorWeight="700"
            >
              {returnData?.tir?.toFixed(2)}%
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              flexDirection: downSm ? 'column' : upXl ? 'row' : 'column',
            }}
          >
            <Typography
              type={
                downSm || !upXl || isFromProposalList
                  ? 'text_x_small'
                  : 'text_medium'
              }
              color="grayscale"
              colorWeight="500"
            >
              Valor Presente Líquido
            </Typography>
            <Spacer size="8" direction="vertical" />
            <Typography
              type={
                downSm || !upXl || isFromProposalList
                  ? 'link_medium'
                  : 'link_small'
              }
              color="grayscale"
              colorWeight="700"
            >
              {returnData?.vpl?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </div>
        </div>
      )}
    </Paper>
  ) : (
    ''
  )
}

export default InvestimentoRetornoCard
