import * as S from './styles'
import { Button } from '@77sol/core'
import { Typography } from 'components'
import { ArrowChevronBackIcon, FilterIcon } from '@77sol/icons/dist'
import { useProductName } from 'hooks/useProductName'

export function Header({ product, isMobile, openFilters, onCancel, onFilter }) {
  const { url, fornecedor } = product ?? {}

  const { productName, availabilityMessage } = useProductName({
    product,
    availabilityLabel: 'Disp. em estoque',
  })

  const headerVariations = {
    title: openFilters ? 'Filtros' : 'Alterar produto',
    onBack: openFilters ? onFilter : onCancel,
  }

  if (isMobile) {
    return (
      <S.Mobile>
        <div className="top">
          <div className="title">
            <ArrowChevronBackIcon
              width="24"
              color="white"
              onClick={headerVariations.onBack}
            />
            <Typography type="link_small" color="white">
              {headerVariations.title}
            </Typography>
          </div>
          {!openFilters && (
            <FilterIcon width="24" color="white" onClick={onFilter} />
          )}
        </div>
        <div className="infos">
          <Typography type="text_small" color="primary" colorWeight="300">
            {productName}
          </Typography>
          {fornecedor && (
            <Typography type="link_x_small" color="grayscale" colorWeight="600">
              {availabilityMessage}
            </Typography>
          )}
        </div>
      </S.Mobile>
    )
  }

  return (
    <>
      <S.Top>
        <Typography
          type="display_x_small_bold"
          color="primary"
          colorWeight="300"
        >
          Alterar produto
        </Typography>
        <Button
          noMargin
          color="red"
          size="small"
          variant="outlined"
          onClick={onCancel}
        >
          Cancelar
        </Button>
      </S.Top>
      <S.Content>
        <S.Image>
          <img src={url} alt={productName} />
        </S.Image>
        <div>
          <Typography type="text_medium" color="primary" colorWeight="300">
            {productName}
          </Typography>
          {fornecedor && (
            <Typography type="link_small" color="grayscale" colorWeight="500">
              {availabilityMessage}
            </Typography>
          )}
        </div>
      </S.Content>
    </>
  )
}
