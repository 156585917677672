import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from 'services/queryClient'
import { Provider } from 'react-redux'
import { ToastProvider } from '@77sol/core'
import store from './store'
import './App.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { setupMockServers } from 'mocks/servers'
import { MOCK_SERVER_ON } from 'constants/mockServerStatus'
import { initTracker } from 'services/tracker/setup'

const root = ReactDOM.createRoot(document.getElementById('root'))

if (MOCK_SERVER_ON) setupMockServers()
initTracker()

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <App />
        <ReactQueryDevtools />
      </ToastProvider>
    </QueryClientProvider>
  </Provider>,
)

serviceWorker.unregister()
