import styled from 'styled-components'
import { colors } from '@77sol-ui/tokens'

interface IRootProps {
  color: 'blue' | 'orange'
  size?: string
}

export const Root = styled.div<IRootProps>`
  & > div {
    border: 1px solid ${({ color }) => colors[color][300]};
    border-radius: 100px;
    padding: 2px 6px;
  }

  span {
    font-size: ${({ size }) => `${size || 12}px`};
  }
`
