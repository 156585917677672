import { ModalAdicionarProduto } from 'views/DimensionarV2/components/StepTwo/components'
import { useAddEquipment } from '../../hooks/useAddEquipment'

export function AddEquipmentModal() {
  const {
    quotationSelected,
    onAddNewEquipment,
    isLoading,
    brands,
    categories,
    openModalToAddNewEquipment,
    setOpenModalToAddNewEquipment,
    userProfile,
  } = useAddEquipment()
  return (
    <ModalAdicionarProduto
      loadingAddProduct={isLoading}
      actualQuote={{ quotation: quotationSelected }}
      handleAddProduct={onAddNewEquipment}
      categories={categories}
      brands={brands}
      modalAdicionarProduto={openModalToAddNewEquipment}
      setModalAdicionarProduto={setOpenModalToAddNewEquipment}
      integratorInfos={userProfile}
    />
  )
}
