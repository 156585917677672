import { useMemo } from 'react'
import { notificationsAll } from '../services/api'
import { useInfiniteQuery } from '@tanstack/react-query'
import { GET_NOTIFICATIONS_ALL } from '../constants/endpointKeys'

export const useFetchNotificationsAll = () => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [GET_NOTIFICATIONS_ALL],
    queryFn: async ({ pageParam = 1 }) => {
      return await notificationsAll.get({ page: pageParam })
    },
    getNextPageParam: ({ current_page, last_page }) => {
      if (current_page < last_page) {
        return current_page + 1
      }
    },
    keepPreviousData: true,
  })

  const notificationList = useMemo(
    () => data?.pages.flatMap((page) => page.data),
    [data],
  )

  const unreadNotifications = useMemo(
    () => Boolean(data?.pages?.[0]?.total_not_read),
    [data],
  )

  return {
    notificationList,
    unreadNotifications,
    ...rest,
  }
}
