import styled from 'styled-components'

export const EquipmentsWrapper = styled.div`
  margin-top: 24px;
`

export const EquipmentsList = styled.div`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
