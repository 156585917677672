import React from 'react'
import { Typography } from 'components'
import Avatar from '@material-ui/core/Avatar'
import { Modal, useMediaQuery, Backdrop, Fade } from '@material-ui/core'
import { Button, Spacer, Paper, Badge } from '@77sol/core'
import { ArrowChevronBackIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { useStyles } from './styles'

function ModalDescricao({
  modalDescricao,
  setModalDescricao,
  datasheetProduct,
}) {
  const classes = useStyles()

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Modal
      id="modal-equipment-description"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalSm}
      open={modalDescricao}
      onClose={() => setModalDescricao(false)}
      closeAfterTransition
      disableEnforceFocus={downSm}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalDescricao} style={{ border: 'none' }}>
        <div className={classes.paperModalDescricao}>
          {downSm ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Paper
                style={{
                  backgroundColor: palette.primary[300],
                  height: 'auto',
                  borderRadius: '0px',
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  padding: '16px',
                  width: '100%',
                }}
              >
                <div
                  style={{ display: 'flex', cursor: 'pointer' }}
                  onClick={() => setModalDescricao(false)}
                >
                  <ArrowChevronBackIcon width="24" color={palette.white} />
                  <Spacer size="8" direction="vertical" />
                  <Typography type="link_small" color="white">
                    Detalhe de produto
                  </Typography>
                </div>
              </Paper>
              <Typography
                type="text_medium"
                color="primary"
                colorWeight="300"
                style={{ marginTop: '56px' }}
              >
                {datasheetProduct.titulo?.split(' - Disp. em estoque:')[0]}
              </Typography>
              <Spacer size="4" direction="horizontal" />
              <Typography
                type="link_x_small"
                color="grayscale"
                colorWeight="500"
              >
                {datasheetProduct.titulo?.split(' - Disp. em estoque:')[1]
                  ? `Disp. em estoque:${
                      datasheetProduct.titulo?.split(' - Disp. em estoque:')[1]
                    }`
                  : 'Disponível em estoque'}
              </Typography>
              {datasheetProduct.garantia && (
                <>
                  <Spacer
                    style={{ minHeight: '12px' }}
                    size="16"
                    direction="horizontal"
                  />
                  <Badge
                    size="large"
                    fullWidth
                    color="primary"
                    variant="contained"
                    label={`${datasheetProduct.garantia} anos de garantia`}
                  />
                </>
              )}
              <Spacer
                style={{ minHeight: '12px' }}
                size="16"
                direction="horizontal"
              />
              {datasheetProduct.datasheet && (
                <Button
                  onClick={() => {
                    window.open(datasheetProduct.datasheet, '_blank').focus()
                  }}
                  noMargin
                  style={{ justifyContent: 'center' }}
                  size="small"
                  variant="outlined"
                >
                  Ver manual técnico
                </Button>
              )}
              <Spacer size="16" direction="horizontal" />
              <Typography
                type="text_small"
                color="grayscale"
                colorWeight="700"
                style={{ overflowY: 'auto' }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: datasheetProduct.descricao,
                  }}
                />
              </Typography>
            </div>
          ) : (
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Avatar
                  src={datasheetProduct.url}
                  variant="rounded"
                  style={{
                    width: '200px',
                    borderRadius: '8px',
                    marginBottom: '24px',
                    height: '200px',
                  }}
                />
                {datasheetProduct.garantia && (
                  <>
                    <Badge
                      size="large"
                      fullWidth
                      color="primary"
                      variant="contained"
                      label={`${datasheetProduct.garantia} anos de garantia`}
                    />
                    <Spacer size="16" direction="horizontal" />
                  </>
                )}
                {datasheetProduct.datasheet && (
                  <Button
                    onClick={() => {
                      window.open(datasheetProduct.datasheet, '_blank').focus()
                    }}
                    style={{ justifyContent: 'center' }}
                    noMargin
                    size="small"
                    variant="outlined"
                  >
                    Ver manual técnico
                  </Button>
                )}
              </div>
              <div
                style={{ display: 'flex', height: '-webkit-fill-available' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '40px',
                  }}
                >
                  <Typography
                    type="display_x_small_bold"
                    color="primary"
                    colorWeight="300"
                  >
                    {datasheetProduct.titulo?.split(' - Disp. em estoque:')[0]}
                  </Typography>
                  <Spacer size="4" direction="horizontal" />
                  <Typography
                    type="link_small"
                    color="grayscale"
                    colorWeight="500"
                  >
                    {datasheetProduct.titulo?.split(' - Disp. em estoque:')[1]
                      ? `Disp. em estoque:${
                          datasheetProduct.titulo?.split(
                            ' - Disp. em estoque:',
                          )[1]
                        }`
                      : 'Disponível em estoque'}
                  </Typography>
                  <Spacer size="32" direction="horizontal" />
                  <Typography
                    type="text_small"
                    color="grayscale"
                    colorWeight="700"
                    style={{ overflowY: 'auto' }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: datasheetProduct.descricao,
                      }}
                    />
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalDescricao
