import styled from 'styled-components'

interface IWrapperProps {
  canShowFooter: boolean
}

export const Wrapper = styled.div<IWrapperProps>`
  position: fixed;
  width: -webkit-fill-available;
  min-height: 100px;
  height: auto;
  background-color: #ffffff;

  padding: 24px 32px;
  bottom: 0;
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

  display: ${({ canShowFooter }) => (canShowFooter ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  transition: 0.4s;

  h1 {
    font-size: 17px;
    font-family: DM Sans;
  }

  p {
    font-family: DM Sans;
    font-size: 17px;
  }

  .arrow {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    svg {
      transition: 0.4s;
      color: #4e4b66;

      &[data-open='true'] {
        rotate: 180deg;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    & .info {
      gap: 4px;
      color: #262338;
      h1 {
        font-weight: 700;
      }

      p {
        font-weight: 400;
      }
    }

    & .actions {
      display: flex;
      align-items: center;
      gap: 12px;

      button > svg {
        display: none;
      }

      @media (max-width: 768px) {
        button > span {
          display: none;
        }
        button > svg {
          display: block;
          width: 16px;
          height: 24px;
        }
      }
    }

    @media (max-width: 768px) {
      display: none;

      &[data-open='true'] {
        display: flex;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 14px 24px;
    min-height: 50px;

    &[data-open='true'] {
      height: auto;
      transition: 0.4s;
      min-height: 100px;
    }

    h1 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
`
