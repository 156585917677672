import { useInfiniteQuery } from '@tanstack/react-query'
import { GET_ORDERS } from '../constants/endpointKeys'
import { ordersService } from '../services/api'
import { useMemo } from 'react'

export const useFetchOrders = () => {
  const { search = '' } = window.location
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [GET_ORDERS, search],
    queryFn: async ({ pageParam = 1 }) =>
      await ordersService.get({ page: pageParam, search }),
    getNextPageParam: ({ current_page, last_page }) => {
      if (current_page < last_page) {
        return current_page + 1
      }
    },
    keepPreviousData: true,
  })

  const ordersList = useMemo(
    () => data?.pages.flatMap((page) => page.data),
    [data],
  )

  return {
    ordersList,
    ...rest,
  }
}
