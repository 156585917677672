import React, { useEffect, useState } from 'react'
import { TextField, Grid, Hidden, useMediaQuery } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import { Badge, Input, Typography } from 'components'
import { Spacer, Button, Paper, useToast } from '@77sol/core'
import { useDispatch } from 'react-redux'
import {
  FilledEditIcon,
  ExchangeTransferIcon,
  DeleteIcon,
  ActionPlusIcon,
  FilterIcon,
  EditIcon,
  CircleActionsAlertInfoIcon,
  LockUnlockedIcon,
  LockIcon,
  FilledAlertInfoIcon,
  RefreshIcon,
} from '@77sol/icons/dist'
import palette from 'app_palette'
import Box from '@material-ui/core/Box'
import { pulse } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { useAmplitude } from 'hooks/useAmplitude'
import { handlePotency, handleOverload } from 'utils'
import API from 'api'
import * as ProposalAction from 'store/actions/index'
import appTheme from 'app_theme'
import { dimensioningTracker } from 'services/tracker/events/dimensioning/trackers'
import { ModalReverterKit } from '../ModalReverterKit/ModalReverterKit'
import { OrcamentoView } from '..'
import { useStyles } from './styles'
import { ModalUpdateSelectedEquipment } from './components/ModalUpdateSelectedEquipment'
import { useRemoveProduct } from 'views/DimensionarV2/hooks/useRemoveProduct'
import { ProductRemoveModal } from 'containers/Modals/ProductRemoveModal'

const stylesAnimation = {
  pulse: {
    animation: 'x 1s infinite',
    animationName: Radium.keyframes(pulse, 'pulse'),
    position: 'absolute',
    left: 'calc(90% - 20px)',
    top: 'calc(10% - 20px)',
    zIndex: 1,
  },
}

export function validateDifferentProvider(quote, product) {
  const productThatNeedChanges = quote?.firstKit?.changeProduct
  const hasChangedProvider = quote?.firstKit?.providers
  const currentProductCategory = product.categoria

  const currentProductIsEqualProductToChange =
    productThatNeedChanges === currentProductCategory

  return !!(currentProductIsEqualProductToChange && hasChangedProvider)
}

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function QuoteTabContent({
  quotes,
  valueTab,
  activeSizing,
  outdatedQuotes,
  handleEditOverload,
  showAlertDiferency,
  setShowAlertDiferency,
  clients,
  isDeletingMobile,
  loadingProducts,
  handleUpdateKitPotency,
  handleChangeQuantity,
  loadingUpdateKitPotency,
  handleChangePotency,
  loadingChangePotency,
  handleOrderProduct,
  setFilterMobile,
  setModalAdicionarProduto,
  setSizingModal,
  setOpenModalShipmentValue,
  newOverload,
  setNewOverload,
  filterAddedItems,
  editingOverload,
  proposal,
  lockedProducts,
  setLockedProducts,
  integratorInfos,
  setModalAttProduct,
  setModalDescricao,
  setDatasheetProduct,
  setProductToChange,
  setModalAlterar,
  setOutdatedProductToAdd,
}) {
  const classes = useStyles()
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()

  const [isOpenRevertModal, setIsOpenRevertModal] = useState(false)
  const [isLoadingRevertKit, setIsLoadingRevertKit] = useState(false)
  const [quoteToRedefine, setQuoteToRedefine] = useState(null)
  const [potencyMarginError, setPotencyMarginError] = useState(10)

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { productToDelete, handleOpenDeleteProduct, handleCloseDeleteProduct } =
    useRemoveProduct()

  function handleOpenDeleteModal(product, quotation) {
    handleOpenDeleteProduct(product)
    setQuoteToRedefine(quotation)
  }

  const { logEvent } = useAmplitude()

  const handleDiferencyPotency = (quote) => {
    const potencyResponse = handlePotency(
      quote?.quotation?.added_items
        ? filterAddedItems(quote?.kit, quote?.quotation?.added_items)
        : quote?.kit,
    )

    const activeSizePotencyCC = (
      Number(activeSizing?.potenciaCC) / 1000
    ).toFixed(2)

    return potencyResponse != activeSizePotencyCC && showAlertDiferency
  }

  const getMarginError = () => {
    API.get('/loja/potency-margin-error').then((res) => {
      setPotencyMarginError(res.data.margin_error)
    })
  }

  useEffect(() => {
    getMarginError()
  }, [])

  async function handleRevertQuotationKit() {
    setIsLoadingRevertKit(true)

    const data = {
      quotation_id: quoteToRedefine.quotation.id,
      redefine_kit: true,
    }

    try {
      const response = await API.post('/proposal/quotation/redefine', data)

      dispatch(ProposalAction.UpdateQuoteRedux(response.data))
      setIsLoadingRevertKit(false)
      setIsOpenRevertModal(false)
      onSuccessOpenToast('Kit redefinido com sucesso!')
    } catch (error) {
      onErrorOpenToast('Falha ao redefinir o kit!')
      setIsLoadingRevertKit(false)
    }
  }

  return (
    <div>
      {quotes?.map((quote, i) => (
        <TabPanel key={i} value={valueTab} index={i}>
          <Hidden xsDown>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Paper
                style={{
                  backgroundColor: palette.grayscale[200],
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '16px 32px',
                  width: '100%',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      marginRight: proposal?.isFromProposalList
                        ? '24px'
                        : '48px',
                      display: 'flex',
                      flexDirection:
                        proposal?.isFromProposalList && !upXl && 'column',
                    }}
                  >
                    <Typography
                      style={{ fontWeight: 800 }}
                      type="link_medium"
                      color="black"
                    >
                      {upXl ? 'Potência do sistema: ' : 'Potência: '}
                    </Typography>
                    <Typography
                      type="text_medium"
                      color="black"
                      style={{ marginLeft: '6px' }}
                    >
                      {handlePotency(
                        quote?.quotation?.added_items
                          ? filterAddedItems(
                              quote?.kit,
                              quote?.quotation.added_items,
                            )
                          : quote?.kit,
                      )}
                      kWp
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: !proposal?.isFromProposalList && 'center',
                      marginRight: proposal?.isFromProposalList
                        ? '24px'
                        : '48px',
                      flexDirection:
                        proposal?.isFromProposalList && !upXl && 'column',
                    }}
                  >
                    {editingOverload ? (
                      <>
                        <Typography
                          style={{ fontWeight: 800 }}
                          type="link_medium"
                          color="black"
                        >
                          {'Sobrecarga: '}
                        </Typography>
                        <Typography type="text_medium" color="black">
                          <TextField
                            size="small"
                            type="number"
                            value={newOverload}
                            onBlur={handleEditOverload}
                            onChange={(e) => setNewOverload(e.target.value)}
                            style={{
                              maxWidth: '50px',
                              marginLeft:
                                proposal?.isFromProposalList && !upXl
                                  ? '0px'
                                  : '3px',
                            }}
                            InputProps={{ className: classes.noPadding }}
                          />
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          style={{ fontWeight: 800 }}
                          type="link_medium"
                          color="black"
                        >
                          {'Sobrecarga: '}
                        </Typography>
                        <Typography
                          type="text_medium"
                          color="black"
                          style={{
                            marginLeft:
                              proposal?.isFromProposalList && !upXl
                                ? '0px'
                                : '6px',
                          }}
                        >
                          {handleOverload(
                            quote?.quotation?.added_items
                              ? filterAddedItems(
                                  quote?.kit,
                                  quote?.quotation.added_items,
                                )
                              : quote?.kit,
                          )}
                          %
                        </Typography>
                      </>
                    )}
                  </div>
                  {(!quote?.quotation?.filter_stock ||
                    quote?.quotation?.filter_stock == '77sol') && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: !proposal?.isFromProposalList && 'center',
                        marginRight: proposal?.isFromProposalList
                          ? '24px'
                          : '48px',
                        flexDirection:
                          proposal?.isFromProposalList && !upXl && 'column',
                      }}
                    >
                      <Typography
                        style={{ fontWeight: 800 }}
                        type="link_medium"
                        color="black"
                      >
                        {'Frete: '}
                      </Typography>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          style={{
                            marginLeft:
                              proposal?.isFromProposalList && !upXl
                                ? '0px'
                                : '6px',
                          }}
                          type="text_medium"
                          color="black"
                        >
                          {{ sem_frete: 'Sem frete' }[
                            quote?.quotation?.delivery_state
                          ] || quote?.quotation?.delivery_state}
                        </Typography>
                        <Tooltip title="Editar frete">
                          <div style={{ display: 'flex' }}>
                            <FilledEditIcon
                              id="quote_button_editShipment"
                              width="16"
                              style={{ cursor: 'pointer', marginLeft: '16px' }}
                              onClick={() => {
                                logEvent(
                                  dimensioningTracker.actions.editShipment,
                                )
                                setOpenModalShipmentValue(true)
                              }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography type="link_medium" color="black">
                    {`# ${quote?.quotation?.id}`}
                  </Typography>
                </div>
              </Paper>
              {proposal?.proposal?.customer_id && (
                <>
                  <Hidden lgDown>
                    <div>
                      <Button
                        id="quote_button_editSizing"
                        noMargin
                        variant="text"
                        style={{ minWidth: 'max-content' }}
                        onClick={() => setSizingModal(activeSizing)}
                      >
                        Editar dimensionamento
                      </Button>
                    </div>
                  </Hidden>
                  <Hidden xlUp>
                    <Tooltip title="editar dimensionamento">
                      <div style={{ display: 'flex' }}>
                        <EditIcon
                          id="quote_buttonIcon_editSizing"
                          onClick={() => setSizingModal(activeSizing)}
                          width="24"
                          color={palette.primary[300]}
                          style={{ cursor: 'pointer', marginLeft: '24px' }}
                        />
                      </div>
                    </Tooltip>
                  </Hidden>
                </>
              )}
            </div>
            {appTheme == 'copernico' && (
              <>
                <Spacer direction="horizontal" size="10" />
                <div>
                  <Paper
                    style={{
                      backgroundColor: palette.grayscale[200],
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '16px 32px',
                      width: '100%',
                    }}
                  >
                    <Typography type="text_medium" color="black">
                      A Sol Copérnico não se responsabiliza por qualquer
                      alteração de produtos que não estejam sendo sugeridos pela
                      plataforma.
                    </Typography>
                  </Paper>
                </div>
              </>
            )}
          </Hidden>
          <Hidden smUp>
            <Paper
              style={{
                padding: '16px',
                backgroundColor: palette.primary[300],
                color: palette.white,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', width: '85%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography type="text_x_small" color="white">
                      Potência
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <Typography type="link_large" color="white">
                        {handlePotency(
                          quote?.quotation?.added_items
                            ? filterAddedItems(
                                quote?.kit,
                                quote?.quotation?.added_items,
                              )
                            : quote?.kit,
                        )}
                      </Typography>
                      <Typography type="text_x_small" color="white">
                        kWp
                      </Typography>
                    </div>
                  </div>
                  <Spacer size="32" direction="vertical" />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography type="text_x_small" color="white">
                      Sobrecarga
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <Typography type="link_large" color="white">
                        {handleOverload(
                          quote?.quotation?.added_items
                            ? filterAddedItems(
                                quote?.kit,
                                quote?.quotation?.added_items,
                              )
                            : quote?.kit,
                        )}
                      </Typography>
                      <Typography type="text_x_small" color="white">
                        %
                      </Typography>
                    </div>
                  </div>
                  {(!quote?.quotation?.filter_stock ||
                    quote?.quotation?.filter_stock == '77sol') && (
                    <>
                      <Spacer size="32" direction="vertical" />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography type="text_x_small" color="white">
                          Frete
                        </Typography>
                        <Typography type="link_large" color="white">
                          {quote?.quotation?.delivery_state}
                        </Typography>
                      </div>
                    </>
                  )}
                </div>
                {(!quote?.quotation?.filter_stock ||
                  quote?.quotation?.filter_stock == '77sol') && (
                  <EditIcon
                    width="24"
                    color={palette.white}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpenModalShipmentValue(true)}
                  />
                )}
              </div>
            </Paper>
          </Hidden>
          {handleDiferencyPotency(quote) && (
            <Hidden xsDown>
              {handleDiferencyPotency(quote) && (
                <Paper
                  style={{
                    padding: '16px 24px',
                    backgroundColor: palette.orange[100],
                    marginTop: '24px',
                  }}
                >
                  <div className={classes.paperPotencyDiferency}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CircleActionsAlertInfoIcon
                        width="24"
                        color={palette.orange[300]}
                        style={{ marginRight: '24px' }}
                      />
                      <div>
                        <Typography
                          type="text_small"
                          color="orange"
                          colorWeight="300"
                        >
                          Os equipamentos selecionados estão fora do
                          dimensionamento
                        </Typography>
                        <Spacer size="8" direction="horizontal" />
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Typography
                              type="text_x_small"
                              color="orange"
                              colorWeight="300"
                            >
                              Potência do kit
                            </Typography>
                            <Typography
                              type="link_large"
                              color="orange"
                              colorWeight="300"
                            >
                              {handlePotency(
                                quote?.quotation?.added_items
                                  ? filterAddedItems(
                                      quote?.kit,
                                      quote?.quotation?.added_items,
                                    )
                                  : quote?.kit,
                              )}
                              kWp
                            </Typography>
                          </div>
                          <Spacer size="16" direction="vertical" />
                          <Divider
                            orientation="vertical"
                            style={{
                              width: '2px',
                              backgroundColor: palette.orange[300],
                              height: 'auto',
                            }}
                          />
                          <Spacer size="16" direction="vertical" />
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Typography
                              type="text_x_small"
                              color="orange"
                              colorWeight="300"
                            >
                              Potência do dimensionamento
                            </Typography>
                            <Typography
                              type="link_large"
                              color="orange"
                              colorWeight="300"
                            >
                              {(activeSizing?.potenciaCC / 1000).toFixed(2)}
                              kWp
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.buttonContainerPotencyDiferency}>
                      <Button
                        id="quoteTabContent_button_updateEquipament"
                        onClick={() =>
                          handleUpdateKitPotency(
                            (activeSizing?.potenciaCC / 1000).toFixed(2),
                            quote.quotation.id,
                          )
                        }
                        isLoading={loadingUpdateKitPotency}
                        variant="outlined"
                        noMargin
                        size="small"
                        color="orange"
                        style={{ marginRight: '16px' }}
                      >
                        Atualizar equipamentos
                      </Button>
                      <Button
                        id="quoteTabContent_button_updateSizing"
                        onClick={() =>
                          handleChangePotency(
                            handlePotency(
                              quote?.quotation?.added_items
                                ? filterAddedItems(
                                    quote?.kit,
                                    quote?.quotation?.added_items,
                                  )
                                : quote?.kit,
                            ),
                          )
                        }
                        isLoading={loadingChangePotency}
                        variant="outlined"
                        noMargin
                        size="small"
                        color="orange"
                        style={{ marginRight: '16px' }}
                      >
                        Atualizar dimensionamento
                      </Button>
                      <Button
                        id="quoteTabContent_button_cancel"
                        variant="text"
                        noMargin
                        color="orange"
                        size="small"
                        onClick={() => setShowAlertDiferency(false)}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </div>
                </Paper>
              )}
            </Hidden>
          )}
          {downSm && handleDiferencyPotency(quote) && (
            <ModalUpdateSelectedEquipment
              handleDiferencyPotency={handleDiferencyPotency}
              setShowAlertDiferency={setShowAlertDiferency}
              quote={quote}
              handlePotency={handlePotency}
              filterAddedItems={filterAddedItems}
              activeSizing={activeSizing}
              handleUpdateKitPotency={handleUpdateKitPotency}
              handleChangePotency={handleChangePotency}
              loadingChangePotency={loadingChangePotency}
              loadingUpdateKitPotency={loadingUpdateKitPotency}
            />
          )}
          <Spacer size="24" direction="horizontal" />
          <Grid container spacing={2}>
            {!downSm && <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />}
            <Grid
              style={{ display: 'flex', alignItems: 'center' }}
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={5}
            >
              <Typography
                type="text_medium"
                color="grayscale"
                colorWeight="600"
              >
                Produto
              </Typography>
            </Grid>
            {!downSm && (
              <Hidden lgDown>
                <Grid
                  style={{ display: 'flex', alignItems: 'center' }}
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <Typography
                    type="text_medium"
                    color="grayscale"
                    colorWeight="600"
                  >
                    Quantidade
                  </Typography>
                </Grid>
              </Hidden>
            )}
            {!downSm && <Grid item xs={4} sm={4} md={1} lg={1} />}
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={3}
            >
              {downSm ? (
                <>
                  <ActionPlusIcon
                    width="24"
                    color={palette.grayscale[800]}
                    style={{ cursor: loadingProducts ? 'auto' : 'pointer' }}
                    onClick={() =>
                      !loadingProducts && setModalAdicionarProduto(true)
                    }
                  />
                  <Spacer size="24" direction="vertical" />
                  <FilterIcon
                    width="24"
                    color={palette.grayscale[800]}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setFilterMobile(true)}
                  />
                  <Spacer size="24" direction="vertical" />
                  <EditIcon
                    width="24"
                    color={palette.grayscale[800]}
                    style={{ cursor: loadingProducts ? 'auto' : 'pointer' }}
                    onClick={() => setSizingModal(activeSizing)}
                  />
                  {handlePotency(
                    quote?.quotation?.added_items
                      ? filterAddedItems(
                          quote?.kit,
                          quote?.quotation?.added_items,
                        )
                      : quote.kit,
                  ) != (activeSizing?.potenciaCC / 1000).toFixed(2) && (
                    <>
                      <Spacer size="24" direction="vertical" />
                      <CircleActionsAlertInfoIcon
                        width="24"
                        color={palette.orange[300]}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowAlertDiferency(true)}
                      />
                    </>
                  )}
                  <Spacer size="18" direction="vertical" />
                  <Tooltip title="Reverter Kit">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <RefreshIcon
                        width="24"
                        color={
                          !quote?.firstKit?.changes ||
                          Object.keys(quote?.firstKit?.changes).length === 0
                            ? palette.grayscale[400]
                            : palette.grayscale[800]
                        }
                        style={{
                          cursor: quote?.firstKit ? 'pointer' : 'not-allowed',
                        }}
                        onClick={() => {
                          if (
                            quote?.firstKit &&
                            Object.keys(quote?.firstKit?.changes).length !== 0
                          ) {
                            setIsOpenRevertModal(true)
                            setQuoteToRedefine(quote)
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                </>
              ) : (
                <div style={{ display: 'flex' }}>
                  <Button
                    id="quoteTabContent_button_addItem"
                    noMargin
                    size="small"
                    variant="outlined"
                    onClick={() => setModalAdicionarProduto(true)}
                    disabled={loadingProducts}
                  >
                    Adicionar item
                  </Button>
                  <Spacer size="12" direction="vertical" />
                  <Button
                    id="quoteTabContent_button_revert"
                    noMargin
                    size="small"
                    onClick={() => {
                      setIsOpenRevertModal(true)
                      setQuoteToRedefine(quote)
                    }}
                    disabled={
                      !quote?.firstKit?.changes ||
                      Object.keys(quote?.firstKit?.changes).length === 0
                    }
                  >
                    Reverter
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
          <Spacer size={downSm ? '16' : '8'} direction="horizontal" />
          <div>
            {handleOrderProduct(quote?.kit)?.map((product, index) => (
              <>
                <Paper
                  key={index}
                  boxShadow="small"
                  style={{
                    padding: '12px 32px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '8px',
                    border: validateDifferentProvider(quote, product)
                      ? `1px solid ${palette.red[300]}`
                      : 'none',
                    backgroundColor:
                      validateDifferentProvider(quote, product) &&
                      isDeletingMobile !== product?.id
                        ? palette.red[50]
                        : validateDifferentProvider(quote, product) &&
                          isDeletingMobile == product?.id
                        ? palette.red[300]
                        : isDeletingMobile == product?.id
                        ? palette.red[300]
                        : outdatedQuotes?.length != 0 &&
                          (outdatedQuotes
                            ?.find((item) => item[0] == quote?.quotation?.id)[1]
                            ?.resultVisible?.find(
                              (item) => item == product?.id,
                            ) ||
                          outdatedQuotes
                            ?.find((item) => item[0] == quote?.quotation?.id)[1]
                            ?.resultValues?.find(
                              (item) => item?.product == product?.id,
                            )
                            ? palette.grayscale[200]
                            : palette.white),
                  }}
                >
                  <Grid
                    key={product.titulo + product.qtde}
                    container
                    spacing={2}
                  >
                    {!downSm && (
                      <Grid
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        xl={1}
                      >
                        <div
                          style={{
                            position: 'relative',
                            cursor:
                              outdatedQuotes?.length != 0 &&
                              (outdatedQuotes
                                ?.find(
                                  (item) => item[0] == quote.quotation.id,
                                )[1]
                                ?.resultValues?.find(
                                  (item) => item.product == product.id,
                                ) ||
                                outdatedQuotes
                                  ?.find(
                                    (item) => item[0] == quote.quotation.id,
                                  )[1]
                                  ?.resultVisible?.find(
                                    (item) => item == product.id,
                                  )) &&
                              'pointer',
                          }}
                          onClick={() => {
                            if (
                              outdatedQuotes?.length != 0 &&
                              outdatedQuotes
                                ?.find(
                                  (item) => item[0] == quote.quotation.id,
                                )[1]
                                ?.resultValues?.find(
                                  (item) => item.product == product.id,
                                )
                            ) {
                              setModalAttProduct(true)
                              setOutdatedProductToAdd(product)
                            }
                            if (
                              outdatedQuotes?.length != 0 &&
                              outdatedQuotes
                                ?.find(
                                  (item) => item[0] == quote.quotation.id,
                                )[1]
                                ?.resultVisible?.find(
                                  (item) => item == product.id,
                                )
                            ) {
                              setProductToChange(product)
                              setModalAlterar(true)
                            }
                          }}
                        >
                          <StyleRoot>
                            {outdatedQuotes?.find(
                              (item) => item[0] == quote.quotation.id,
                            ) && (
                              <>
                                {outdatedQuotes
                                  ?.find(
                                    (item) => item[0] == quote.quotation.id,
                                  )[1]
                                  ?.resultValues?.find(
                                    (item) => item.product == product.id,
                                  ) && (
                                  <Tooltip title="Houve alterações no valor deste produto. Clique aqui para atualizar.">
                                    <div style={stylesAnimation.pulse}>
                                      <CircleActionsAlertInfoIcon
                                        color="#FFF"
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                          backgroundColor: palette.orange[300],
                                          borderRadius: '50%',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          setModalAttProduct(true)
                                          setOutdatedProductToAdd(product)
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                )}
                                {outdatedQuotes
                                  ?.find(
                                    (item) => item[0] == quote.quotation.id,
                                  )[1]
                                  ?.resultVisible?.find(
                                    (item) => item == product.id,
                                  ) && (
                                  <Tooltip title="Este produto não está mais disponível. Clique aqui para alterar.">
                                    <div style={stylesAnimation.pulse}>
                                      <CircleActionsAlertInfoIcon
                                        color="#FFF"
                                        style={{
                                          width: '25px',
                                          height: '25px',
                                          backgroundColor: palette.orange[300],
                                          borderRadius: '50%',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          setProductToChange(product)
                                          setModalAlterar(true)
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </StyleRoot>
                          <Avatar
                            src={product?.url}
                            variant="rounded"
                            style={{ width: '72px', height: '72px' }}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                      item
                      xs={12}
                      sm={12}
                      md={7}
                      lg={7}
                      xl={5}
                    >
                      <Typography
                        type={downSm ? 'link_x_small' : 'text_medium'}
                        color={
                          isDeletingMobile == product.id ? 'white' : 'primary'
                        }
                        colorWeight="300"
                        style={{
                          cursor:
                            downSm &&
                            (!quote?.quotation?.filter_stock ||
                              quote?.quotation?.filter_stock == '77sol') &&
                            'pointer',
                        }}
                        onClick={() => {
                          if (downSm) {
                            if (
                              !quote.quotation.filter_stock ||
                              quote.quotation.filter_stock == '77sol'
                            ) {
                              setDatasheetProduct(product)
                              setModalDescricao(true)
                            }
                          }
                        }}
                      >
                        {product?.titulo?.split(' - Disp. em estoque:')[0]}{' '}
                        {integratorInfos?.privilegios_admin
                          ? currentEnterpriseConfigs.formatProductName(
                              product?.valor,
                            )
                          : ''}
                      </Typography>
                      {quote?.firstKit?.providers &&
                        quote?.firstKit?.changeProduct ===
                          product?.categoria && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Hidden xsDown>
                              <FilledAlertInfoIcon
                                width="14px"
                                color={
                                  isDeletingMobile === product?.id
                                    ? 'white'
                                    : 'red'
                                }
                              />
                              <Spacer size="4" direction="vertical" />
                            </Hidden>
                            <Typography
                              type="text_x_small"
                              color={
                                isDeletingMobile === product?.id
                                  ? 'white'
                                  : 'red'
                              }
                              colorWeight="300"
                            >
                              Este item é de outro estoque e não pode compor o
                              kit gerador.
                            </Typography>
                          </div>
                        )}

                      {!validateDifferentProvider(quote, product) && (
                        <Typography
                          type={downSm ? 'text_x_small' : 'link_small'}
                          color={
                            isDeletingMobile == product.id
                              ? 'white'
                              : 'grayscale'
                          }
                          colorWeight="500"
                        >
                          {product?.titulo?.split(' - Disp. em estoque:')[1]
                            ? `Disp. em estoque:${
                                product?.titulo?.split(
                                  ' - Disp. em estoque:',
                                )[1]
                              }`
                            : 'Disponível em estoque'}
                        </Typography>
                      )}

                      <div style={{ display: 'flex' }}>
                        {product?.garantia ? (
                          <>
                            {!validateDifferentProvider(quote, product) && (
                              <Typography
                                type={downSm ? 'text_small' : 'link_medium'}
                                color={
                                  isDeletingMobile == product.id
                                    ? 'white'
                                    : 'green'
                                }
                                colorWeight="300"
                              >
                                {`${product?.garantia} anos de garantia`}
                              </Typography>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {quote?.firstKit &&
                        quote?.firstKit?.changes[product.categoria] && (
                          <>
                            <Spacer size="6" direction="horizontal" />
                            <Badge color="orange" size="small">
                              Alterado
                            </Badge>
                          </>
                        )}
                    </Grid>
                    {!downSm && (
                      <Grid
                        style={{ display: 'flex', alignItems: 'center' }}
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        xl={2}
                      >
                        <Input
                          label="Unidades"
                          defaultValue={product.qtde}
                          type="number"
                          size="small"
                          minDate={1}
                          onBlur={(e) => {
                            if (e.target.value < 1) {
                              handleChangeQuantity(
                                quote.quotation.id,
                                product.id,
                                1,
                              )
                            } else {
                              handleChangeQuantity(
                                quote.quotation.id,
                                product.id,
                                e.target.value,
                              )
                            }
                          }}
                        />
                      </Grid>
                    )}
                    {!downSm && (
                      <Grid
                        style={{ display: 'flex', alignItems: 'center' }}
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        xl={3}
                      >
                        {(!quote?.quotation?.filter_stock ||
                          quote?.quotation?.filter_stock == '77sol') && (
                          <Typography
                            id="quote_button_technicalData"
                            onClick={() => {
                              setDatasheetProduct(product)
                              setModalDescricao(true)
                            }}
                            style={{ cursor: 'pointer' }}
                            type="link_x_small"
                            color="primary"
                            colorWeight="300"
                          >
                            Ver dados técnicos
                          </Typography>
                        )}
                      </Grid>
                    )}
                    <Grid
                      style={{
                        display: 'flex',
                        justifyContent: downSm ? 'space-between' : 'flex-end',
                        alignItems: 'center',
                      }}
                      item
                      xs={12}
                      sm={12}
                      md={9}
                      lg={9}
                      xl={1}
                    >
                      {downSm && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Input
                            style={{ maxWidth: '120px' }}
                            label="Unidades"
                            defaultValue={product.qtde}
                            type="number"
                            size="small"
                            minDate={1}
                            onBlur={(e) => {
                              if (e.target.value < 1) {
                                handleChangeQuantity(
                                  quote.quotation.id,
                                  product.id,
                                  1,
                                )
                              } else {
                                handleChangeQuantity(
                                  quote.quotation.id,
                                  product.id,
                                  e.target.value,
                                )
                              }
                            }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        {(!quote?.quotation?.filter_stock ||
                          quote?.quotation?.filter_stock == '77sol') && (
                          <>
                            {lockedProducts?.findIndex(
                              (prod) => prod == product?.id,
                            ) != -1 ? (
                              <Tooltip title="Desbloquear produto">
                                <div style={{ display: 'flex' }}>
                                  <LockIcon
                                    color={palette.primary[300]}
                                    width="24"
                                    onClick={() => {
                                      const newArr = [...lockedProducts]
                                      const lockedProductIndex =
                                        lockedProducts.findIndex(
                                          (prod) => prod == product.id,
                                        )
                                      newArr.splice(lockedProductIndex, 1)
                                      setLockedProducts([...newArr])
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Bloquear produto">
                                <div style={{ display: 'flex' }}>
                                  <LockUnlockedIcon
                                    color={palette.grayscale[300]}
                                    width="24"
                                    onClick={() => {
                                      const newArr = [...lockedProducts]
                                      newArr.push(product.id)
                                      setLockedProducts([...newArr])
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                              </Tooltip>
                            )}
                            {(product?.categoria == 61 ||
                              product?.categoria == 63 ||
                              product?.categoria == 62 ||
                              product?.categoria == 66) && (
                              <Spacer size="24" direction="vertical" />
                            )}
                          </>
                        )}
                        {(product?.categoria == 61 ||
                          product?.categoria == 63 ||
                          product?.categoria == 62 ||
                          product?.categoria == 66) && (
                          <>
                            {quote?.firstKit?.providers &&
                            quote?.firstKit?.changeProduct ===
                              product?.categoria ? (
                              <Button
                                color="red"
                                size="small"
                                noMargin
                                variant="outlined"
                                startIcon={<ExchangeTransferIcon width="18" />}
                                onClick={() => {
                                  logEvent(
                                    dimensioningTracker.actions
                                      .listItemChangeProduct,
                                  )
                                  setProductToChange(product)
                                  setModalAlterar(true)
                                }}
                              >
                                Alterar
                              </Button>
                            ) : (
                              <Tooltip title="Alterar produto">
                                <div style={{ display: 'flex' }}>
                                  <ExchangeTransferIcon
                                    id={`kitList_button_openUpdateProductModal_${product?.id}`}
                                    onClick={() => {
                                      logEvent(
                                        dimensioningTracker.actions
                                          .listItemChangeProduct,
                                      )
                                      setProductToChange(product)
                                      setModalAlterar(true)
                                    }}
                                    width="24"
                                    color={palette.grayscale[800]}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </>
                        )}
                        <Spacer size="24" direction="vertical" />
                        <Tooltip title="Remover produto">
                          <div style={{ display: 'flex' }}>
                            <DeleteIcon
                              id={`kitList_button_removeProduct_${product?.id}`}
                              onClick={() => {
                                handleOpenDeleteModal(product, quote)
                              }}
                              width="24"
                              color={palette.red[300]}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
                <Spacer size="8" direction="horizontal" />
              </>
            ))}
          </div>
          <div style={{ position: 'absolute', left: '-20000px' }}>
            <OrcamentoView
              style={{ position: 'absolute', left: '-20000px' }}
              integrador={
                integratorInfos?.nome
                  ? integratorInfos?.nome
                  : proposal?.userName
              }
              cliente={clients?.find(
                (client) => client.id == proposal.proposal.customer_id,
              )}
              potencia={handlePotency(
                quote?.quotation?.added_items
                  ? filterAddedItems(quote?.kit, quote?.quotation?.added_items)
                  : quote?.kit,
              )}
              desconto={quote?.quotation?.discount_percentage}
              valorTotal={quote?.quotation?.equipment_value}
              produtos={quote?.kit}
              id={quote.quotation?.id}
              adminClient={quote?.quotation?.integrator_id}
              frete={quote?.quotation?.delivery_state}
              criadoData={quote?.quotation?.created_at}
              isAdmin={proposal.isAdmin}
              delivery_days={quote?.quotation?.delivery_days}
            />
          </div>
        </TabPanel>
      ))}
      {productToDelete && (
        <ProductRemoveModal
          product={productToDelete}
          quotationId={quoteToRedefine?.quotation?.id}
          onClose={handleCloseDeleteProduct}
          onSuccess={handleCloseDeleteProduct}
        />
      )}
      {isOpenRevertModal && (
        <ModalReverterKit
          isOpenRevertModal={isOpenRevertModal}
          setIsOpenRevertModal={setIsOpenRevertModal}
          handleRevertQuotationKit={handleRevertQuotationKit}
          isLoadingRevertKit={isLoadingRevertKit}
        />
      )}
      <Spacer size={downSm ? '120' : '122'} direction="horizontal" />
    </div>
  )
}

export default QuoteTabContent
