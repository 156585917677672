import logo from '../../assets/img/logo-77sol.svg'
import logoPDF from '../../assets/img/77sol-img.png'
import logoWhite from '../../assets/img/logo-77sol-white.svg'
import logoOrder from '../../assets/img/logo.svg'
import { type EnterpriseInformation } from '../globalConfig.types'

export const defaultEnterpriseConfig: EnterpriseInformation = {
  appinfo: {
    language: 'pt',
    tab_icon: '77sol.png',
    logo,
    logoPDF,
    logo_white: logoWhite,
    logo_order: logoOrder,
    favicon: 'assets/img/favicon-77sol.svg',
    favicon_white: 'assets/img/favicon-77sol-white.svg',
    corporateName: '77 SOL TECNOLOGIA SA.',
    name: '77Sol',
    document: '41.015.655/0001-10',
    phone: '(11) 93060-7771',
    email: 'contato@77sol.com.br',
    address:
      'R. Gomes de Carvalho, 1108 - Vila Olímpia, São Paulo - SP, 04547-004',
    secondAddress: '',
    site: '',
    metatags: {
      title: '',
      url: '',
      image: '',
      site_name: '',
      description: '',
      keywords: '',
      author: '',
    },
    socials: [
      {
        media: 'Facebook',
        icon: 'fa-facebook-f',
        url: 'https://www.facebook.com/setesetesol',
      },
      {
        media: 'Instagram',
        icon: 'fa-instagram',
        url: 'https://www.instagram.com/setesetesol/',
      },
      {
        media: 'Youtube',
        icon: 'fa-youtube',
        url: 'https://www.youtube.com/channel/UC4iUMgpDin0WtMoSgZC1eRQ',
      },
      {
        media: 'Linkedin',
        icon: 'fa-linkedin-in',
        url: 'https://www.linkedin.com/company/77sol/',
      },
    ],
    show_campaigns: {
      supportMaterials: true,
    },
  },
}
