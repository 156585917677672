import React from 'react'
import { Button } from '@77sol/core'
import { FileIcon } from '@77sol/icons/dist'
import { Box, useMediaQuery } from '@material-ui/core'

import * as S from './styles'

export function Header({ title, onBack, viewProject, visibleButtons }) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <S.Header>
      <S.TitleContainer onClick={onBack}>
        <S.BackIcon width={24} />
        <S.Title>{title}</S.Title>
      </S.TitleContainer>
      {visibleButtons && (
        <>
          {downSm ? (
            <Box display="flex">
              <FileIcon
                width={24}
                height={24}
                onClick={viewProject}
                color="white"
                style={{ cursor: 'pointer' }}
              />
            </Box>
          ) : (
            <Button
              size="small"
              color="primary"
              variant="outlined"
              noMargin
              onClick={viewProject}
            >
              Visualizar Projeto
            </Button>
          )}
        </>
      )}
    </S.Header>
  )
}
