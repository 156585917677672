import { useForm } from 'react-hook-form'
import { orderPendingSchema } from '../validators/orderPendingValidator'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import api from 'api'

export const usePendingForm = (orderId: string) => {
  const { ...methods } = useForm({
    defaultValues: {
      customer_phone: '',
      nf_value: 0,
    },
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    resolver: yupResolver(orderPendingSchema),
  })

  useEffect(() => {
    const fetchOrderData = async () => {
      const { data } = await api.get(`/loja/pedidos/${orderId}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.getItem('sessionToken'),
        },
      })

      const infoPedido = data.pedido[0]
      const pedido = { ...infoPedido, quotation: data?.pedido?.[4] }
      methods.reset({
        nf_value: Number(pedido?.nf_value_user).toFixed(2) as unknown as number,
        nf_origin: pedido?.pendings?.nf_origin ?? '',
        customer_phone: pedido?.pendings?.customer_phone ?? '',
        customer_email: pedido?.pendings?.customer_email ?? '',
        birth_date: pedido.pendings?.birth_date ?? '',
        equipment_value: pedido.quotation?.equipment_value ?? 0,
        payment_type: pedido.pendings?.payment_type ?? '',
        billing_letter: {
          file: null,
          name_file: pedido.pendings?.billing_letter?.name_file ?? null,
          url: pedido.pendings?.billing_letter?.url ?? null,
        },
        address_payment: {
          file: null,
          name_file: pedido.pendings?.address_payment?.name_file ?? null,
          url: pedido.pendings?.address_payment?.url ?? null,
        },
      })
    }

    if (orderId) fetchOrderData()
  }, [orderId, methods.reset])

  return { ...methods }
}
