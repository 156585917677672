import { type IQuotationDTO } from 'domains/dimensioning/services/types'
import { CompareKitCardHeader } from './components/Header'
import { ProductList } from './components/ProductList'
import * as S from './styles'
import { Button } from '@77sol-ui/atoms'
import { useReserveEquipments } from 'containers/EquipmentQuotationContainer/hooks/useReserveEquipments'
import { DeleteQuotationButton } from './components/DeleteQuotationButton'
import { useAmplitude } from 'hooks/useAmplitude'
import { bottomMenuTracker } from 'services/tracker/events/bottomMenu/trackers'
import { TAB_ENUM_AMPLITUDE } from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'
import { AvailableFlag } from 'containers/EquipmentQuotationContainer/components/Flags/AvailableFlag'
import { DeliveryFlag } from 'containers/EquipmentQuotationContainer/components/Flags/DeliveryFlag'

interface CompareKitCardProps {
  quotation: IQuotationDTO
}

export function CompareKitCard({ quotation }: CompareKitCardProps) {
  const { handleReserveEquipments: reserveEquipments } = useReserveEquipments()
  const { logEvent } = useAmplitude()
  const { disabled } = useDisableActionButtons()

  const handleReserveEquipments = () => {
    logEvent(bottomMenuTracker.actions.equipmentBooking, {
      origin: '/cotar',
      tab: TAB_ENUM_AMPLITUDE['compare-kit'],
    })
    reserveEquipments(quotation.id)
  }

  return (
    <S.Card>
      <S.DeleteButtonContainer>
        <DeleteQuotationButton quotation={quotation} />
      </S.DeleteButtonContainer>
      <S.FlagsContainer>
        <DeliveryFlag />
        <AvailableFlag
          availableAt={quotation.available_at}
          deliveredBy={quotation.delivered_by}
        />
      </S.FlagsContainer>
      <CompareKitCardHeader
        id={quotation.id}
        overload={quotation.overload}
        potency={quotation.potency}
        equipmentValue={quotation.equipment_value}
      />
      <ProductList products={quotation.products} />
      <div className="buttonContainer">
        <Button
          variant="secondary"
          disabled={disabled}
          onClick={() => {
            handleReserveEquipments()
          }}
        >
          Reservar equipamentos
        </Button>
      </div>
    </S.Card>
  )
}
