import { Box } from '@material-ui/core'
import { CheckoutModal, Loading } from 'components'
import { QuotationContainer } from 'containers'
import { isEmpty } from 'utils'
import { Modal, Button, Spacer } from '@77sol/core'
import { BoxIcon } from '@77sol/icons/dist'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { useBuyModal } from './hooks/useBuyModal'
import { useCheckoutModalStore } from '../../../store/modals/CheckoutModalStore'

function BuyModal({ isOpen, handleCloseModal, proposalId }) {
  const resetCheckoutModal = useCheckoutModalStore((state) => state.reset)

  const {
    quotations,
    handleClose,
    cartQuote,
    handleConfirm,
    selectedQuotation,
    setSelectedQuotation,
    isCartLoading,
    isLoading,
    setIsCheckoutModalOpen,
    isCheckoutModalOpen,
  } = useBuyModal({
    handleCloseModal,
    proposalId,
  })

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Loading isLoading={isLoading}>
          <QuotationContainer
            quotations={quotations}
            onChange={setSelectedQuotation}
            TitleIcon={BoxIcon}
            title={currentEnterpriseConfigs.budgetTitle}
            subtitle={currentEnterpriseConfigs.budgetSubtitle}
          />
          <Box display="flex">
            <Button
              size="small"
              disabled={isEmpty(selectedQuotation)}
              onClick={handleConfirm}
              isLoading={isCartLoading}
            >
              {currentEnterpriseConfigs.budgetButtonTitle}
            </Button>
            <Spacer size="16" direction="both" />
            <Button variant="outlined" size="small" onClick={handleClose}>
              Cancelar
            </Button>
          </Box>
        </Loading>
      </Box>
      {isCheckoutModalOpen && (
        <CheckoutModal
          cotacaoId={cartQuote}
          onCloseModal={() => {
            setIsCheckoutModalOpen(false)
            resetCheckoutModal()
          }}
          openModal={isCheckoutModalOpen}
        />
      )}
    </Modal>
  )
}

export default BuyModal
