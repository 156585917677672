import styled from 'styled-components'
import { colors } from '@77sol-ui/tokens'

interface ProductWrapperProps {
  noDivider: boolean
}
export const Title = styled.span`
  font-weight: 700;
`
export const ProductListWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  max-height: 304px;
  padding-right: 8px;
`

export const ProductWrapper = styled.div<ProductWrapperProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: ${({ noDivider }) =>
    noDivider ? 'unset' : `solid 2px ${colors.gray[200]}`};
  padding: 10px 0px;

  & .productTitle {
    max-width: 65%;
    font-size: 1rem;
  }

  & .quantity {
    font-size: 1.25rem;
  }

  img {
    width: 64px;
  }

  @media screen and (max-width: 768px) {
    & .productTitle {
      width: 50%;
      font-size: 0.9rem;
    }
  }
`
