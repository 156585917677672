import React, { useState } from 'react'
import { Typography, Input } from 'components'
import { useMediaQuery } from '@material-ui/core'
import { Button, Spacer, Paper, Badge, useToast } from '@77sol/core'
import {
  ArrowChevronForwardIcon,
  ArrowChevronUpIcon,
  RoomServiceHelpIcon,
  NotificationBellIcon,
} from '@77sol/icons/dist'
import palette from 'app_palette'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { parseBRL } from 'utils'
import API from 'api'
import { BADGE_PLAN_COLORS } from 'constants/badgePlanColors'
import { useAmplitude } from 'hooks/useAmplitude'
import { bottomMenuTracker } from 'services/tracker/events/bottomMenu/trackers'
import { useSelector } from 'react-redux'
import { useStyles } from './styles'

function BottomMenu({
  proposal,
  sizingWidth,
  completeQuoteEdit,
  setCompleteQuoteEdit,
  actualQuote,
  handleFinishQuote,
  handleUpdateCart,
  setSelectClientModal,
  plan,
  updateActualQuote,
  isOpenMobile,
  setIsOpenMobile,
  setQuoteIdEditing,
  integratorInfos,
  isCancelingQuotationOrProject,
}) {
  const classes = useStyles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { logEvent } = useAmplitude()

  const { onErrorOpenToast } = useToast()

  const { id: userId } = useSelector(
    (state) => state.ReducerProfile.userProfile,
  )

  const actionsButtonVisible = !actualQuote?.userInternal && !proposal?.isAdmin

  const [editTotalValue, setEditTotalValue] = useState(false)
  const [totalValue, setTotalValue] = useState(
    formatarMoeda(
      parseFloat(actualQuote?.quotation?.equipment_value).toFixed(2),
    ),
  )
  const [loadingChangeValue, setLoadingChangeValue] = useState()

  function formatarMoeda(e) {
    let valor = e

    valor += ''
    valor = parseInt(valor.replace(/[\D]+/g, ''))
    valor += ''
    valor = valor.replace(/([0-9]{2})$/g, ',$1')

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')
    }

    if (!valor || valor === '' || Number.isNaN(valor) || valor === 'NaN') {
      return 0
    }
    return valor
  }

  const handleUpdateValue = () => {
    setLoadingChangeValue(true)

    API.post('/proposal/quotation/edit/value', {
      quotation_id: actualQuote.quotation.id,
      total_value: totalValue.split('.').join('').replace(',', '.'),
    })
      .then(() => {
        updateActualQuote()
        setLoadingChangeValue(false)
        setEditTotalValue(false)
      })
      .catch(() => {
        setLoadingChangeValue(false)
      })
  }

  const badgePlan = BADGE_PLAN_COLORS[plan]

  const handleCreateProject = () => {
    logEvent(bottomMenuTracker.actions.createProject)
    setSelectClientModal(true)
  }
  const handleEquipmentBooking = (id) => {
    logEvent(bottomMenuTracker.actions.equipmentBooking)
    handleUpdateCart(id)
  }

  const handleNext = () => {
    logEvent(bottomMenuTracker.actions.next)
    handleFinishQuote()
  }

  const handleCompleteQuote = () => {
    setCompleteQuoteEdit(false)

    if (setQuoteIdEditing) setQuoteIdEditing('')
  }

  return (
    <Paper
      style={{
        flexDirection: 'column',
        padding: downSm ? '8px 24px' : '24px 32px 32px 32px',
        width:
          downSm || currentEnterpriseConfigs?.hideQuotationFilterColumn
            ? '100%'
            : `calc(100% - ${sizingWidth})`,
        borderRadius: '8px 8px 0px 0px',
        bottom: '0px',
        position: 'absolute',
        left: '0px',
        display: 'flex',
        height: downSm && !isOpenMobile ? '115px' : '136px',
        zIndex: 99,
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.04)',
        transition: 'height 0.3s ease-in-out 0s',
      }}
    >
      {downSm && (
        <div
          className={isOpenMobile ? classes.iconButton : classes.iconExpanded}
        >
          <ArrowChevronUpIcon
            style={{ cursor: 'pointer' }}
            onClick={() =>
              isOpenMobile ? setIsOpenMobile(false) : setIsOpenMobile(true)
            }
            width="24"
            color={palette.grayscale[600]}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {editTotalValue ? (
            <div style={{ marginBottom: '8px' }}>
              <Input
                value={totalValue}
                setValue={setTotalValue}
                size="small"
                placeholder="Valor total"
                currencyFormat
                onBlur={() => handleUpdateValue()}
                submit={() => {}}
                loading={loadingChangeValue}
              />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="700"
              >
                {'Valor total: '}
                {actualQuote?.quotation?.equipment_value
                  ? parseBRL(
                      parseFloat(actualQuote?.quotation?.equipment_value),
                    )
                  : ''}
              </Typography>
            </div>
          )}
          {((downSm && isOpenMobile) || !downSm) &&
            currentEnterpriseConfigs.planBadge && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  type="text_small"
                  color="grayscale"
                  colorWeight="700"
                >
                  Desconto:
                </Typography>
                <Spacer size="8" direction="vertical" />
                {plan && (
                  <Badge
                    label={plan}
                    color={badgePlan.color}
                    colorWeight={badgePlan.weight}
                  />
                )}
              </div>
            )}
        </div>
        {proposal.proposal.customer_id ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {completeQuoteEdit ? (
              <Button
                onClick={handleCompleteQuote}
                className={classes.filledButton}
                disabled={proposal?.quotes?.find(
                  (item) => item?.firstKit?.providers == true,
                )}
              >
                Finalizar
              </Button>
            ) : (
              <Button
                id="sizingBottomMenu_button_next"
                onClick={handleNext}
                noMargin
                size="small"
                disabled={
                  proposal?.quotes?.find(
                    (item) => item?.firstKit?.providers == true,
                  ) || isCancelingQuotationOrProject
                }
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {!downSm && 'Avançar'}
                  <ArrowChevronForwardIcon
                    style={{ marginLeft: !downSm && '12px' }}
                    width="16"
                  />
                </div>
              </Button>
            )}
          </div>
        ) : (
          <div>
            {actualQuote?.quotation?.seller_id === userId ? (
              <div>
                {integratorInfos?.nome && (
                  <Button
                    onClick={() => {
                      if (!integratorInfos?.telefone) {
                        onErrorOpenToast(
                          'Telefone do integrador não cadastrado!',
                        )
                      } else {
                        const url = `https://api.whatsapp.com/send?phone=${`55${(integratorInfos?.telefone)
                          .replace('-', '')
                          .replace(' ', '')
                          .replace('(', '')
                          .replace(')', '')}`}&text=Ol%C3%A1%20${
                          integratorInfos?.nome
                        }.%20Voc%C3%AA%20pode%20encontrar%20sua%20nova%20cota%C3%A7%C3%A3o%20personalizada%20em%20sua%20listagem%20de%20projetos%20pelo%20c%C3%B3digo%20%23${
                          proposal?.proposal?.project_id
                        }.%0A%0AOu%20acesse%3A${` admin.77sol.com.br/visualizar/cotacaoV2/${actualQuote?.dimensioning?.proposal_id}`}%20para%20visualizar%20e%20reservar%20seu%20kit.`
                        window.open(url, '_blank')
                      }
                    }}
                    noMargin
                    size="small"
                    startIcon={<NotificationBellIcon />}
                    onlyIcon={downSm && <NotificationBellIcon />}
                  >
                    {`Notificar ${integratorInfos?.nome?.split(' ')[0]}`}
                  </Button>
                )}
              </div>
            ) : (
              actionsButtonVisible && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: downSm ? 'row-reverse' : 'row',
                  }}
                >
                  <Button
                    onClick={handleCreateProject}
                    noMargin
                    size="small"
                    disabled={proposal?.quotes?.find(
                      (item) => item?.firstKit?.providers == true,
                    )}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {downSm ? (
                        <ArrowChevronForwardIcon
                          style={{ marginLeft: !downSm && '12px' }}
                          width="16"
                        />
                      ) : (
                        'Criar projeto'
                      )}
                    </div>
                  </Button>
                  {(!actualQuote?.quotation?.filter_stock ||
                    actualQuote?.quotation?.filter_stock == '77sol') && (
                    <>
                      <Spacer size="16" direction="vertical" />
                      {!actualQuote?.quotation?.reserved && (
                        <Button
                          onClick={() =>
                            handleEquipmentBooking(actualQuote?.quotation?.id)
                          }
                          variant="outlined"
                          noMargin
                          size="small"
                          disabled={proposal?.quotes?.find(
                            (item) => item?.firstKit?.providers == true,
                          )}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {downSm ? (
                              <RoomServiceHelpIcon
                                style={{ marginRight: !downSm && '12px' }}
                                width="16"
                              />
                            ) : (
                              <>{currentEnterpriseConfigs.orderButtonTitle}</>
                            )}
                          </div>
                        </Button>
                      )}
                    </>
                  )}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </Paper>
  )
}

export default BottomMenu
