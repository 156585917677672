import * as S from './styles'
import { Badge as BadgeCore } from '@77sol/core'

interface IBadgeProps {
  color: 'primary' | 'orange'
  label: string
  size?: string
}

export function Badge({ color, label, size, ...props }: IBadgeProps) {
  const currentBadgeColor = color === 'primary' ? 'blue' : color

  return (
    <S.Root color={currentBadgeColor} size={size} {...props}>
      <BadgeCore color={color} label={label} />
    </S.Root>
  )
}
