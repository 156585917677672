import { Typography } from '@77sol/core'
import { useStyles } from './styles'
import { type IKitItem } from 'types/sizing/kit.types'

export interface KitItemProps {
  item: IKitItem
}

export function KitItem({ item }: KitItemProps): JSX.Element {
  const classes = useStyles()

  return (
    <div data-testid="container" className={classes.container}>
      <Typography color="grayscale" colorWeight="800" size="small">
        {item.titulo}
      </Typography>
      <div className={classes.amount}>
        <Typography color="grayscale" colorWeight="800" size="large">
          {item.qtde}
        </Typography>
        <Typography color="grayscale" colorWeight="800" size="xsmall">
          &nbsp;un
        </Typography>
      </div>
    </div>
  )
}
