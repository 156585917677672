import { Typography, BarChart } from '@77sol/core'
import { palette } from '@77sol/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { parseBRL } from 'utils'

export function ProjectBudget({ quote, returnData, i }) {
  const proposal = useSelector((state) => state.Reducer1)

  return (
    <div style={{ width: '100%', marginTop: '12px' }}>
      <Typography
        style={{
          fontSize: '12px',
          backgroundColor: palette.grayscale[300],
          textAlign: 'center',
          padding: '4px',
          color: palette.grayscale[700],
        }}>
        Projeto{' '}
        {proposal.sizings.findIndex(
          (sizing) => sizing.id == quote.dimensioning.id,
        ) + 1}{' '}
        - Orçamento{' '}
        {proposal.quotes
          .filter((_quote) => _quote.dimensioning.id == quote.dimensioning.id)
          .findIndex((_quote) => _quote.quotation.id == quote.quotation.id) + 1}
      </Typography>
      <table style={{ width: '100%', borderSpacing: '0px' }}>
        <tr>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 8px',
            }}
            colSpan="10">
            <Typography
              style={{
                fontSize: '8px',
                lineHeight: '12px',
                color: palette.grayscale[500],
              }}>
              Você investe
            </Typography>
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                color: palette.grayscale[700],
              }}>
              {parseBRL(proposal?.quotes[i]?.quotation?.total_value)}
            </Typography>
          </td>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 8px',
            }}
            colSpan="10">
            <Typography
              style={{
                fontSize: '8px',
                lineHeight: '12px',
                color: palette.grayscale[500],
              }}>
              Em {returnData?.anos} anos você economiza
            </Typography>
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                color: palette.grayscale[700],
              }}>
              {returnData?.dadosConsolidados[
                returnData.dadosConsolidados.length - 1
              ].fluxoAcumuladoAnual?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </td>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 8px',
            }}
            colSpan="10">
            <Typography
              style={{
                fontSize: '8px',
                lineHeight: '12px',
                color: palette.grayscale[500],
              }}>
              Tenha seu retorno em
            </Typography>
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                color: palette.grayscale[700],
              }}>
              {returnData?.payback} anos
            </Typography>
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 8px',
            }}
            colSpan="15">
            <Typography
              style={{
                fontSize: '8px',
                lineHeight: '12px',
                color: palette.grayscale[500],
              }}>
              VPL - Valor presente líquido
            </Typography>
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                color: palette.grayscale[700],
              }}>
              {parseBRL(returnData?.vpl)}
            </Typography>
          </td>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 8px',
            }}
            colSpan="15">
            <Typography
              style={{
                fontSize: '8px',
                lineHeight: '12px',
                color: palette.grayscale[500],
              }}>
              TIR - Taxa interna de retorno
            </Typography>
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                color: palette.grayscale[700],
              }}>
              {returnData?.tir.toFixed(2)}%
            </Typography>
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 10px',
            }}
            colSpan="30">
            {returnData && <BarChart returnData={returnData} />}
          </td>
        </tr>
        <tr style={{ backgroundColor: palette.grayscale[300] }}>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 10px',
              color: palette.grayscale[700],
            }}
            colSpan="6">
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                textAlign: 'center',
              }}>
              Ano
            </Typography>
          </td>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 10px',
              color: palette.grayscale[700],
            }}
            colSpan="6">
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                textAlign: 'center',
              }}>
              Produção anual
            </Typography>
          </td>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 10px',
              color: palette.grayscale[700],
            }}
            colSpan="6">
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                textAlign: 'center',
              }}>
              Tarifa
            </Typography>
          </td>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 10px',
              color: palette.grayscale[700],
            }}
            colSpan="6">
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                textAlign: 'center',
              }}>
              Economia anual
            </Typography>
          </td>
          <td
            style={{
              border: '1px solid #DFE3E8',
              padding: '4px 10px',
              color: palette.grayscale[700],
            }}
            colSpan="6">
            <Typography
              style={{
                fontSize: '10px',
                lineHeight: '15px',
                textAlign: 'center',
              }}>
              Saldo
            </Typography>
          </td>
        </tr>
        {returnData?.dadosConsolidados.map((item, index) => (
          <>
            {((index + 1) % 5 == 0 || index === 0) && (
              <tr>
                <td
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}
                  colSpan="6">
                  <Typography
                    style={{
                      fontSize: '8px',
                      lineHeight: '12px',
                      textAlign: 'center',
                      color: palette.grayscale[700],
                    }}>
                    {index + 1}
                  </Typography>
                </td>
                <td
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}
                  colSpan="6">
                  <Typography
                    style={{
                      fontSize: '8px',
                      lineHeight: '12px',
                      textAlign: 'center',
                      color: palette.grayscale[700],
                    }}>
                    {item.producaoAnual.toFixed(2)} kWh
                  </Typography>
                </td>
                <td
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}
                  colSpan="6">
                  <Typography
                    style={{
                      fontSize: '8px',
                      lineHeight: '12px',
                      textAlign: 'center',
                      color: palette.grayscale[700],
                    }}>
                    {parseBRL(item.tarifaAnual)}
                  </Typography>
                </td>
                <td
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}
                  colSpan="6">
                  <Typography
                    style={{
                      fontSize: '8px',
                      lineHeight: '12px',
                      textAlign: 'center',
                      color: palette.grayscale[700],
                    }}>
                    {parseBRL(item.receitaAnual)}
                  </Typography>
                </td>
                <td
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}
                  colSpan="6">
                  <Typography
                    style={{
                      fontSize: '8px',
                      lineHeight: '12px',
                      textAlign: 'center',
                      color: palette.grayscale[700],
                    }}>
                    {parseBRL(item.fluxoAcumuladoAnual)}
                  </Typography>
                </td>
              </tr>
            )}
          </>
        ))}
      </table>
    </div>
  )
}
