export function SaveProposalRedux(proposal) {
  return {
    type: 'SET_PROPOSAL',
    proposal,
  }
}

export function SaveClientsRedux(clients) {
  return {
    type: 'SET_CLIENTS',
    clients,
  }
}

export function SaveSizingRedux(sizing) {
  return {
    type: 'SET_SIZING',
    sizing,
  }
}

export function UpdateSizingRedux(sizing) {
  return {
    type: 'UPDATE_SIZING',
    sizing,
  }
}

export function SaveStepRedux(step) {
  return {
    type: 'SET_STEP',
    step,
  }
}

export function SavenivelLoginRedux(nivel) {
  return {
    type: 'SET_NIVEL_LOGIN',
    nivel,
  }
}

export function SaveQuoteRedux(quote) {
  return {
    type: 'SET_QUOTE',
    quote,
  }
}

export function UpdateQuoteRedux(quote, keepWarning) {
  return {
    type: 'UPDATE_QUOTE',
    quote,
    keepWarning,
  }
}

export function UpdateQuoteDataRedux(quoteData) {
  return {
    type: 'UPDATE_DATA',
    quoteData,
  }
}

export function RemoveQuoteRedux(quoteId) {
  return {
    type: 'REMOVE_QUOTE',
    quoteId,
  }
}

export function RemoveSizingRedux(sizingId) {
  return {
    type: 'REMOVE_SIZING',
    sizingId,
  }
}

export function SaveStocksRedux(stocks) {
  return {
    type: 'SET_STOCKS',
    stocks,
  }
}

export function SaveProductsRedux(products) {
  return {
    type: 'SET_PRODUCTS',
    products,
  }
}

export function SaveCategoriesRedux(categories) {
  return {
    type: 'SET_CATEGORIES',
    categories,
  }
}

export function SaveBrandsRedux(brands) {
  return {
    type: 'SET_BRANDS',
    brands,
  }
}

export function SaveUserNameRedux(userName) {
  return {
    type: 'SET_USER_NAME',
    userName,
  }
}

export function SaveNewSizingRedux(newSizing) {
  return {
    type: 'SET_NEW_SIZING',
    newSizing,
  }
}

export function SaveQuoteFilter(filters) {
  return {
    type: 'SET_QUOTE_FILTER',
    filters,
  }
}

export function SaveActiveSizingRedux(sizing) {
  return {
    type: 'SET_ACTIVE_SIZING',
    sizing,
  }
}

export function ChangeQtdProduct(quoteId, productId, qtd) {
  return {
    type: 'CHANGE_PRODUCT_QTD',
    value: [quoteId, productId, qtd],
  }
}

export function SaveExtraConfigsRedux(extraConfigs) {
  return {
    type: 'SET_EXTRA_CONFIGS',
    extraConfigs,
  }
}

export function SetShowForPayment(showForPayment) {
  return {
    type: 'SET_SHOW_FOR_PAYMENT',
    showForPayment,
  }
}

export function SetIsAdmin(isAdmin) {
  return {
    type: 'SET_IS_ADMIN',
    isAdmin,
  }
}

export function setIsProPlanModalOpen(isProPlanModalOpen) {
  return {
    type: 'SET_IS_PRO_PLAN_MODAL_OPEN',
    isProPlanModalOpen,
  }
}

export function setIsFromProposalList(isFromProposalList) {
  return {
    type: 'SET_IS_FROM_PROPOSAL_LIST',
    isFromProposalList,
  }
}

export function setOriginLead(leadType) {
  return {
    type: 'LEAD_TYPE',
    leadType,
  }
}

export function reserveOutdatedQuote(outdatedQuote) {
  return {
    type: 'OUTDATED_QUOTE',
    outdatedQuote,
  }
}

export function ResetRedux() {
  return {
    type: 'RESET',
  }
}
