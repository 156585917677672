import { useEquipmentContainerStore } from '../store/EquipmentQuotationStore'

export const useCreateProject = () => {
  const setOpenProjectModal = useEquipmentContainerStore(
    (state) => state.setOpenModalToCreateProject,
  )

  const handleCreateProject = () => {
    setOpenProjectModal(true)
  }

  return { handleCreateProject }
}
