import styled from 'styled-components'

export const NoItemsContainer = styled.div`
  display: flex;
`

export const Container = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px;
`

export const Item = styled.div`
  hr {
    height: 1px !important;
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  width: 100%;
`

export const ItemTitleInfo = styled.div`
  display: flex;
  align-items: center;

  p {
    max-width: 244px;
  }
`
export const Title = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 6px;
  width: 90%;
`

export const ItemImage = styled.img`
  width: 80px;
`

export const ItemAmountInfo = styled.div`
  display: flex;
  gap: 10px;
`

export const ItemAmount = styled.div`
  display: flex;
`

export const DropdownItemWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  & svg {
    width: 22px;
  }
`
