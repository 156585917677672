import { Button } from '@77sol-ui/atoms'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'

export function AddEquipmentButton() {
  const { logEvent } = useAmplitude()
  const { setOpenModalToAddNewEquipment } = useEquipmentContainerStore()

  function handleOpenAddEquipmentModal() {
    logEvent(quotationTracker.actions.addNewEquipment, {
      origin: '/cotar',
    })
    setOpenModalToAddNewEquipment(true)
  }

  return (
    <Button variant="outline" onClick={handleOpenAddEquipmentModal}>
      Adicionar Item
    </Button>
  )
}
