import styled from 'styled-components'

export const QuotationTotal = styled.div`
  p {
    font-weight: 700;
    display: flex;
    gap: 4px;
  }

  span {
    font-weight: 500;
  }
`
