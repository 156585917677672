import { DropdownMenu } from '@77sol/core'
import { KebabMenu } from 'components/KebabMenu'
import { useAmplitude } from 'hooks/useAmplitude'
import React from 'react'
import { productTracker } from 'services/tracker/events/product/trackers'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'

interface IEquipmentMenuProps {
  onShowTechnicalData: () => void
  onDelete: () => void
}

const EquipmentMenu: React.FC<IEquipmentMenuProps> = ({
  onShowTechnicalData,
  onDelete,
}) => {
  const { logEvent } = useAmplitude()

  const handleShowTechnicalData = () => {
    logEvent(productTracker.actions.showTechnicalData, {
      origin: '/Cotar',
    })
    onShowTechnicalData()
  }

  const handleDelete = () => {
    logEvent(quotationTracker.actions.deleteProduct, {
      origin: '/Cotar',
    })
    onDelete()
  }

  return (
    <KebabMenu color="#828282">
      <DropdownMenu.Item onClick={handleShowTechnicalData}>
        Ver dados técnicos
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={handleDelete}>Excluir</DropdownMenu.Item>
    </KebabMenu>
  )
}

export { EquipmentMenu }
