import * as S from './styles'
import { EquipmentCard } from '../EquipmentCard'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { EquipmentsHeader } from './components/EquipmentsHeader'
import { AddEquipmentModal } from './components/AddEquipmentModal'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'

export function Equipments() {
  const { quotationSelected, openModalToAddNewEquipment } =
    useEquipmentContainerStore()

  return (
    <>
      <S.EquipmentsWrapper>
        <EquipmentsHeader />
        <S.EquipmentsList>
          {quotationSelected?.products?.map((product: IQuotationProductDTO) => (
            <EquipmentCard key={product.id} product={product} />
          ))}
        </S.EquipmentsList>
      </S.EquipmentsWrapper>
      {openModalToAddNewEquipment && <AddEquipmentModal />}
    </>
  )
}
