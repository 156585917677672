import { Button } from '@77sol-ui/atoms'
import { Bell } from 'lucide-react'
import { useNotifyIntegrator } from './hooks/useNotifyIntegrator'
import { type IProposalDTO } from 'dto/ProposalDTO'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'

export function ButtonNotifyIntegrator({
  integrator,
}: Pick<IProposalDTO, 'integrator'>) {
  const { handleNotifyIntegrator } = useNotifyIntegrator({
    integrator,
  })

  const { disabled } = useDisableActionButtons()

  return (
    <Button onClick={handleNotifyIntegrator} disabled={disabled}>
      <Bell width={16} />
      {`Notificar ${integrator.name}`}
    </Button>
  )
}
