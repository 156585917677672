import styled from 'styled-components'

export const Card = styled.div`
  position: relative;
  padding: 18px 24px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  min-width: 452px;
  width: 452px;
  min-height: 666px;

  & .buttonContainer {
    width: 100%;
    margin-inline: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    padding: 0px 20px;

    button {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    min-width: 100%;
    min-height: 646px;
  }
`

export const DeleteButtonContainer = styled.div`
  width: 24px;
`

export const FlagsContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  gap: 8px;
`
